import { app, InitApp } from "./frame"
import { useUserStore, useAppStore, useCommonStore } from "@/store"
import useFrameStore from "./frameStore"
import { pollLocale } from "../utils/locale.js"
import { commonJS } from "@pacvue/utils"
import { analyticsIdentify, useAnalyticsTrack } from "./analytics/index.js"
import { loadAppcues } from "./appcues"
import config from "@~/config.js"
import { writeLoginInfoAndNavigate } from "../utils/login.js"
import { handleAuthCodeAndReloadPage } from "../utils/nav.js"
import { keepCurrentPersistedData } from "../store/cache-restore.js"
import { getPltAnnouncement } from "./common"
import { shouldReload } from "./timeout"
import { DOMAIN_MAPPER_PROD } from "../constants/platform.js"

function endRequest() {
  window.$httpArray.forEach((item) => {
    item()
  })
  window.$httpArray = []
}

export default function (router) {
  router.beforeEach(async (to, from, next) => {
    console.log(`from: ${from.path} navigate to: ${to.path}`)
    const _this = this || {}
    const productLine = window.productline || localStorage.getItem("productline")

    const nextFn = () => {
      sessionStorage.removeItem("perDataKept")
      next()
    }

    // 特殊平台的跳转路径预处理拦截
    if (InitApp.pathPreprocessor) {
      const isDifferentPath = await InitApp.pathPreprocessor(to, from, next)
      if (isDifferentPath) return
    }

    if (to.query.authcode) {
      await handleAuthCodeAndReloadPage(from, to)
      next()
      return
    }

    if (to.query.crossNav || (from.path === "/" && sessionStorage.getItem("perDataKept") != 1)) {
      await keepCurrentPersistedData()
      // 保证此 if block 不会再次进入
      sessionStorage.setItem("perDataKept", "1")
      const destPath = to.query.redirect || to.path
      const extraParams = to.query.payload ? { query: { query: to.query.payload } } : {}
      // 通过路由过程中的 query 信息告知后续 userInfo 及菜单请求不能使用缓存
      if (to.query.noCache) {
        if (!extraParams.query) {
          extraParams.query = {}
        }
        extraParams.query.noCache = "1"
      }
      // 不开放登录页的平台: HQ, 乐天 还需要记录跳转而来的源平台
      if (config.noLogin && to.query.query) {
        localStorage.setItem("navSrcPlt", to.query.query)
      }
      // 直接进入下一钩子，并顺带清除 path query
      next(to.query.crossNav ? { path: destPath, ...extraParams } : { path: to.path, query: to.query })
      return
    }

    // 假如有前置的清除操作
    if (_this.clearingFn && typeof _this.clearingFn === "function") {
      _this.clearingFn()
    }

    // 只有新国际化加载方案才需要轮询国际化并缓存
    if (!["commerce-walmart", "retailer"].includes(productLine)) {
      await pollLocale()
    }

    if (to.fullPath.lastIndexOf("/login") != -1 || to.fullPath.lastIndexOf("loginoauth") != -1 || to.name === "Auth" || to.name === "ActiveEmail") {
      document.getElementById("pacvue-loading").style.display = "none"
      nextFn()
      return
    }

    const userStore = useUserStore()
    const frameStore = useFrameStore() // identical to appStore
    frameStore.SET_activePath(to.path)

    try {
      if (!to.query.shareId) {
        if ((!userStore.user || !userStore.user.userName) && !to.meta.noRefreshToken) {
          let phase = 0
          let currPhaseInterceptors = []
          while ((currPhaseInterceptors = InitApp.userInfoInterceptors[`phase${phase++}`] || []).length > 0) {
            const promises = currPhaseInterceptors.map((itc) => Promise.resolve(itc(to, from, next)))
            await Promise.all(promises)
          }
          app.config.globalProperties.$eventHub.emit("getUserInfo", userStore.user)
        }
        if (!to.meta.noRefreshToken) {
          const requestList = []
          if (frameStore.menu.length === 0) {
            requestList.push(frameStore.meueAction({ to, from }))
          }
          if (!userStore.userSettings) {
            requestList.push(userStore.userSetting({ to, from }))
          }
          if (requestList.length > 0) {
            await Promise.all(requestList)
          }
        }
        if (to.meta.noRefreshToken || userStore.userSettings) {
          const el = document.getElementById("app")
          if (userStore?.userSettings?.themeColour == "white") {
            document.getElementsByTagName("body")[0].setAttribute("class", "theme1")
          } else if (userStore?.userSettings?.themeColour == "black") {
            document.getElementsByTagName("body")[0].setAttribute("class", "theme2 borderversion")
          } else {
            document.getElementsByTagName("body")[0].setAttribute("class", "theme2")
          }
        }
        // 偏好语言与当前环境不同, 则需要重刷页面, 拿对应语言最新的语言包
        if (window.prefLangDiffered) {
          const url = new URL(window.location.href)
          const params = new URLSearchParams(url.search)
          const pathSlices = url.pathname.split("/")
          if (from.path.includes("login") || from.path === "/Auth") {
            window.open(`${url.origin}${pathSlices.slice(0, pathSlices.length - 1).join("/")}${to.path}?${params.toString()}`, "_self")
          } else {
            window.location.reload()
          }
          nextFn()
          return
        }
        /* 偏好相关 End */

        for (let i in InitApp.routerInterceptors) {
          await InitApp.routerInterceptors[i](to, from, next)
        }
      }
    } catch (err) {
      next("/login")
    }
    app.config.globalProperties.$eventHub.emit("routerBeforeHook", { to, from, next })

    if (!_this.hasPermissionProxy && to.meta?.alias && window.NoPermissionPage?.[to.meta.alias]) {
      next({ name: "NoPermission" })
    }

    /* 登录最终步骤 Start */
    if ((from.path.indexOf("login") !== -1 || from.path === "/") && localStorage.getItem("SignFlag") == "true") {
      localStorage.setItem("SignFlag", false)
      await analyticsIdentify()
      const clientSelect = localStorage.getItem("isMultiClient") ? "Select Client" : "No need to select"
      useAnalyticsTrack("Login Success", {
        category: "Login",
        "login type": "username",
        "Client Select": clientSelect
      })
      if (userStore.user && !userStore.user.isEmailValidated && userStore.userSettings && !userStore.userSettings.mailValidatePop) {
        localStorage.setItem("EmailVerifyPrompt", true)
      }
      localStorage.removeItem("isMultiClient")
      let autoLogoutPath
      if (from.query.Rurl) {
        autoLogoutPath = isNaN(from.query.Rurl) ? from.query.Rurl : localStorage.getItem(`pacvue_logout_path_${from.query.Rurl}`)
      }
      // 若路由的某次钩子进行到最后一步仍是去往欢迎页, 则直接放行
      if (to.path === "/Guide") {
        nextFn()
        endRequest()
      } else {
        next(autoLogoutPath || window.HomePage || window.firstRouter)
        return
      }
    }
    /* 登录最终步骤 End */

    nextFn()
    endRequest()
  })

  router.afterEach((to, from, next) => {
    document.getElementById("pacvue-loading").style.display = "none"
    if (!config.noAppcues && !window.Appcues && window.AppcuesSettings && !to.path.includes("login")) {
      loadAppcues()
    }
    !config.noGlobalAnnouncement && getPltAnnouncement(useUserStore().user)
    app.config.globalProperties.$eventHub.emit("routerAfterEachHook", { to, from, next })
  })

  router.onError((error, to) => {
    const productLine = window.productline || localStorage.getItem("productline")
    if (error.message.includes("Failed to fetch dynamically imported module")) {
      if (shouldReload()) {
        // 存储当前时间戳
        sessionStorage.setItem("lastReloadTime", new Date().getTime())
        // 刷新页面
        const pathPrefix = DOMAIN_MAPPER_PROD[productLine]?.pathPrefix || ""
        window.location.replace(`/${pathPrefix}${to.fullPath}`.replace("//", "/"))
      }
    }
  })
}
