const realTimePages = [
  {
    alias: "Ad_Campaign",
    menu: "Campaign"
  },
  {
    alias: "Ad_Adgroup",
    menu: "Adgroup"
  },
  {
    alias: "Ad_Targeting",
    menu: "Targeting"
  },
  {
    alias: "Campaign Tagging",
    menu: "CampaignTag"
  },
  {
    alias: "Ad_Asin",
    menu: "Asin"
  },
  {
    alias: "AdGroupSub",
    menu: "Adgroup"
  }
]

export function getRealTimePart(props = ["alias"]) {
  return realTimePages.map((item) => {
    var propKey = props[0]
    if (props.length == 1) {
      return item[propKey]
    }
    return item
  })
}
