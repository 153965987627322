import { useAnalyticsTrack } from "./analytics/index.js"
import { dayjs, PacvueMessage, PacvueMessageBox } from "@pacvue/element-plus"
import CryptoJS from "crypto-js"
import { commonJS } from "@pacvue/utils"
import { getCrossAuthCode } from "./api"
import request from "./request"
import { DOMAIN_MAPPER_TEST, DOMAIN_MAPPER_PROD } from "../constants/platform"
import { isDev, isCN, isEU, isLocal, isWhiteLabel } from "../constants/env"

// 货币符号转换两种应用方式：
// 1. v-currency="当前行的currency",比如v-currency="scope.row.currency"
// 2. this.formatExchangeRate('带货币符号的数据', 需要转换成的currency)
// 注意!!!  v-currency不可在同一标签上跟v-if v-else同时使用，会失效 或者 列表等发生怪异现象比如列对应数据错位
function formatExchangeRate(oldV, currency = "euro") {
  let mapping = { euro: "€" }
  let reg = /\€/
  let sessioncurrency = sessionStorage.getItem("currency")
  if (sessioncurrency) {
    mapping = JSON.parse(sessioncurrency)
    let values = Object.values(mapping).join("")
    reg = new RegExp("[" + values + "]")
  }
  if (reg.test(oldV)) {
    return oldV.replace(reg, mapping[currency && currency.toLocaleLowerCase()] || "€")
  }
  return oldV
}

function copyJSON(str) {
  var a = JSON.stringify(str)
  var b = JSON.parse(a)
  return b
}

function formatData(num, type = "", symbol = "€") {
  var lowerType = type ? type.toLowerCase() : ""
  if (isNaN(num)) {
    return lowerType.indexOf("budget") != -1 ? "--" : ""
  } else {
    if (lowerType) {
      if (lowerType == "spend" || lowerType == "spent") {
        return formatSpendsMoney(num, symbol)
      } else if (lowerType.indexOf("budget") != -1) {
        return num == 0 ? "--" : formatBudgetMoney(num, symbol)
      } else if (lowerType.indexOf("sales") != -1) {
        return formatSalesMoney(num, symbol)
      } else if (
        lowerType.indexOf("acos") != -1 ||
        lowerType.indexOf("sov") != -1 ||
        ["ctr", "cvr", "dsfrequency", "shareofshelf", "top3rate", "top3times", "adsfrequency", "ofprofilespend", "ofcampaignspend", "percent", "perOfTotalSpends", "perOfTotalSales"].includes(
          lowerType
        )
      ) {
        return formatPercent100(num, symbol)
      } else if (
        [
          "impression",
          "impressions",
          "click",
          "clicks",
          "conversion",
          "conversions",
          "conversionsotherean",
          "inventorycount",
          "saleunits",
          "clicksaleunits",
          "totalorders",
          "reviewscount",
          "averagerating",
          "avgposition",
          "avgorganicposition",
          "avgadposition",
          "instock",
          "orders",
          "clickorders",
          "searchvolume"
        ]
          .map((e) => e.toLocaleLowerCase())
          .includes(lowerType)
      ) {
        return toThousandsx(num)
      } else {
        return formatMoney(num, symbol)
      }
    } else {
      return formatMoney(num, symbol)
    }
  }
}

function c(str) {
  var a = JSON.stringify(str)
  var b = JSON.parse(a)
  return b
}

function formatFullData(num, type, symbol = "€") {
  if (isNaN(num)) {
    return ""
  }
  if (num == 0) {
    return formatData(num, type)
  } else {
    let lowerType = type ? type.toLowerCase() : ""
    if (lowerType) {
      if (
        lowerType.indexOf("acos") != -1 ||
        lowerType.indexOf("sov") != -1 ||
        lowerType.indexOf("rate") != -1 ||
        [
          "ctr",
          "cvr",
          "dsfrequency",
          "shareofshelf",
          "top3rate",
          "adsfrequency",
          "ofprofilespend",
          "ofcampaignspend",
          "percent",
          "buyboxpct",
          "availabilitypct",
          "buyboxpctlastweek",
          "availabilitypctlastweek"
        ].includes(lowerType)
      ) {
        return formatPercent100(num, symbol)
      } else if (
        [
          "impression",
          "impressions",
          "conversion",
          "conversions",
          "click",
          "clicks",
          "clickorders",
          "orders",
          "inventorycount",
          "saleunits",
          "totalorders",
          "reviewscount",
          "averagerating",
          "avgposition",
          "pageviews",
          "pageviewlastweek"
        ].includes(lowerType) ||
        lowerType.indexOf("units") != -1
      ) {
        return toThousands(num)
      } else {
        return symbol + toThousands(num)
      }
    } else {
      return symbol + toThousands(num)
    }
  }
}

function formatSpendsMoney(money = 0, num = "€") {
  var type = num

  // if (num && num == "￥") {
  //   return type + toThousands(Math.round(new Number(money)));
  // }
  if (money >= 1000000) {
    return type + toThousands((money / 1000000).toFixed(1)) + "M"
  } else if (money >= 10000) {
    return type + toThousands(new Number(money / 1000).toFixed(1)) + "K"
  }
  // else if (money > 1) {
  //   return type + toThousands(new Number(money).toFixed(0));
  // } else {
  //   return type + toThousands(new Number(money).toFixed(2));
  // }
  else {
    return type + toThousands(new Number(money).toFixed(2))
  }
}

function formatBudgetMoney(money = 0, symbol = "€") {
  var type = symbol

  // if (symbol && symbol == "￥") {
  //   return type + toThousands(Math.round(new Number(money)));
  // }
  // if (money >= 1000000) {
  //   return type + toThousands((money / 1000000).toFixed(0)) + "M";
  // } else if (money > 10000) {
  //   return type + toThousands(new Number(money / 1000).toFixed(0)) + "K";
  // } else if (money > 1) {
  //   return type + toThousands(new Number(money).toFixed(0));
  // } else {
  //   return type + toThousands(new Number(money).toFixed(2));
  // }

  return type + toThousands(new Number(money).toFixed(2))
}
// function formatBudgetMoney(t = 0, symbol = '$') {
//   if (isNaN(t)) {
//     return symbol + '0';
//   }
//   var num = new Number(t);
//   num = parseFloat(num);
//   num = num.toString().split('.'); // 分隔小数点
//   var arr = num[0].split('').reverse(); // 转换成字符数组并且倒序排列
//   var res = [];
//   for (var i = 0, len = arr.length; i < len; i++) {
//     if (i % 3 === 0 && i !== 0) {
//       res.push(','); // 添加分隔符
//     }
//     res.push(arr[i]);
//   }
//   res.reverse(); // 再次倒序成为正确的顺序
//   // 小数不用管了
//   // if (num[1]) {
//   //   // 如果有小数的话添加小数部分
//   //   res = res.join('').concat('.' + num[1]);
//   // } else {
//   //   res = res.join('');
//   // }
//   return symbol + res.join('');
// }
function formatSalesMoney(money = 0, num = "€") {
  var type = num

  // if (num && num == "￥") {
  //   return type + toThousands(Math.round(new Number(money)));
  // }
  if (money >= 1000000) {
    return type + toThousands((money / 1000000).toFixed(1)) + "M"
  } else if (money >= 10000) {
    return type + toThousands(new Number(money / 1000).toFixed(1)) + "K"
  }
  // else if (money > 10) {
  //   return type + toThousands(new Number(money).toFixed(0));
  // } else if (money > 0.1) {
  //   return type + toThousands(new Number(money).toFixed(1));
  // } else {
  //   return type + toThousands(new Number(money).toFixed(2));
  // }
  else {
    return type + toThousands(new Number(money).toFixed(2))
  }
}

function formatPercent100(v = 0, symbol = "€") {
  v = new Number(v)
  if (v >= 100) {
    return v.toFixed(0) + "%"
  } else {
    return (symbol == "€" ? v.toFixed(2).replace(".", ",") : v.toFixed(2)) + "%"
  }
}

function formatPercent(v, symbol = "€") {
  v = new Number(v)
  return (symbol == "€" ? v.toFixed(2).replace(".", ",") : v.toFixed(2)) + "%"
}
function formatPercentToe(value) {
  let str = ""
  if (value > 0) {
    let p = Math.floor(Math.log(value) / Math.LN10)
    let val = (value / 10 ** p).toFixed(1)
    str = val + "e" + p + "%"
  } else {
    value = Number(value.toString().substring(1, value.length))
    let p = Math.floor(Math.log(value) / Math.LN10)
    let val = (value / 10 ** p).toFixed(1)
    str = "-" + val + "e" + p + "%"
  }
  return str
}

function toThousands(price = 0, currency) {
  let result = price
  if (currency === "euro") {
    result = price.toLocaleString("de-DE")
  } else {
    result = price.toLocaleString()
  }

  return result
}

function formatMoney(money = 0, num = "€") {
  var type = num

  if (num && num == "￥") {
    return type + toThousands(Math.round(new Number(money)))
  }
  money = new Number(money)
  if (money > 1000000) return type + toThousands((money / 1000000).toFixed(2)) + "M"
  else if (money > 10000) return type + toThousands(new Number(money / 1000).toFixed(2)) + "K"
  else return type + toThousands(new Number(money).toFixed(2))
}

function formatMoney1(money, type = "€") {
  if (type && type == "￥") {
    return type + toThousands(Math.round(new Number(money)))
  }
  return type + toThousands(new Number(money).toFixed(2))
}

function toThousandsx(t) {
  t = new Number(t)
  if (t > 1000000) {
    return toThousands((t / 1000000).toFixed(1)) + "M"
  } else if (t > 10000) {
    return toThousands((t / 1000).toFixed(1)) + "K"
  } else {
    return toThousands(t.toFixed(0))
  }
}

function parseDate(date, type) {
  if (!date) return "--"

  return dayjs(date).format(type)
}

function getTimeByZoon(format = "YYYY-MM-DD HH:MM:SS") {
  let zoon = "EST"
  let dateStr = ""
  switch (zoon) {
    case "EST":
      dateStr = getESTTime(format)
      break
  }
  return dateStr
}

function getESTTime(formatStr) {
  let hours = dayjs().isDST() ? 4 : 5,
    est_date = dayjs.utc().subtract(hours, "hours").format(formatStr)
  return est_date
}

function formatPercent1(v) {
  v = new Number(v)
  return v.toFixed(1) + "%"
}

function colorclass1(j, val) {
  var k = j.toLowerCase()
  let arr = [
      "ctr",
      "cvr",
      "click",
      "clicks",
      "spc",
      "sales",
      "relatedclicksales",
      "brandviewsales",
      "viewsales",
      "roas",
      "clickSales",
      "clicksales",
      "clickROAS",
      "clickroas",
      "brandClickSales",
      "brandClickROAS",
      "offLineSales",
      "offlinesales",
      "brandclicksales",
      "brandclickroas",
      "troas",
      "revenue",
      "saleunits",
      "orders",
      "clickorders",
      "organicrevenue",
      "totalorders",
      "brandHaloClickSales",
      "brandhaloclicksales",
      "brandHaloClickROAS",
      "brandhaloclickroas",
      "shareOfShelf",
      "shareofshelf",
      "organicSOV",
      "organicsov",
      "spPaidSOV",
      "sppaidsov",
      "adsFrequency",
      "adsfrequency",
      "avgPosition",
      "avgposition",
      "avgOrganicPosition",
      "avgorganicposition",
      "avgAdPosition",
      "avgadposition",
      "avgPaidPosition",
      "avgpaidposition",
      "topThreeRate",
      "topthreerate",
      "pageOneRate",
      "pageonerate",
      "sbPaidSOV",
      "sbpaidsov",
      "paidSOV",
      "paidsov",
      "conversion"
    ],
    arr1 = ["acos", "cpa", "cpc", "cpm", "clickACOS", "clickacos", "clickorders", "brandClickACOS", "brandclickacos", "tacos", "brandHaloClickACOS", "brandhaloclickacos"]
  if (val == 0) {
    return "gray"
  } else if (arr.includes(k)) {
    return dataISminus(val) ? "red" : "green"
  } else if (arr1.includes(k)) {
    return dataISminus(val) ? "green" : "red"
  } else {
    return "gray"
  }
}
function dataISminus(val) {
  if (val <= 0) {
    return true
  } else {
    return false
  }
}

function colorclass(j, val) {
  j = (j || "").toLowerCase()
  if (
    j.indexOf("sales") != -1 ||
    j.indexOf("roas") != -1 ||
    j.indexOf("position") != -1 ||
    j.indexOf("paidsov") != -1 ||
    j.indexOf("orders") != -1 ||
    ["ctr", "cvr", "click", "clicks", "spc", "revenue", "saleunits", "organicrevenue", "shareofshelf", "organicsov", "adsfrequency", "topthreerate", "pageonerate", "conversion"].includes(j)
  ) {
    return val == 0 ? "gray" : val < 0 ? redType(val) : "green"
  } else if (j.indexOf("acos") != -1 || ["cpa", "cpc", "cpm"].includes(j)) {
    return val == 0 ? "gray" : val < 0 ? "green" : redType(val)
  } else {
    return "gray"
  }
}
function redType(val) {
  let newVal = val > 0 ? val : 0 - val
  if (newVal <= 50) {
    return "yellow"
  } else {
    return "red"
  }
}
// function colorclass(j, val) {
//   j = (j || "").toLowerCase();
//   if (
//     [
//       "ctr",
//       "click",
//       "clicks",
//       "spc",
//       "sales",
//       "roas",
//       "clicksales",
//       "clickroas",
//       "saleunits",
//       "orders",
//       "cvr",
//       "viewsales",
//       "brandclicksales",
//       "brandviewsales",
//       "relatedclicksales",
//       "brandhaloclicksales",
//       "brandhaloclickroas",
//     ].includes(j)
//   ) {
//     return val == 0 ? "#999999" : val < 0 ? redType(val) : "#5CBF8E";
//   } else if (["acos", "cpa", "cpc", "clickacos", "brandhaloclickacos"].includes(j)) {
//     return val == 0 ? "#999999" : val < 0 ? "#5CBF8E" : redType(val);
//   } else {
//     return "#999999";
//   }
// }

// function redType(val) {
//   let newVal = val > 0 ? val : 0 - val;
//   if (newVal < 51) {
//     return "#FBAF46";
//   } else {
//     return "#EE8873";
//   }
// }

function equalArr(a, b) {
  //增加判断，数组定义后为空是null清空的数组相等判断
  if ((a == null && b && b.length == 0) || (a && a.length == 0 && b == null)) {
    return false
  }
  if (!b || !a) {
    return true
  }
  if (a.length != b.length) {
    return true
  }
  for (var i = 0; i < a.length; i++) {
    if (a[i] instanceof Array && b[i] instanceof Array) {
      if (equalArr(a[i], b[i])) return true
    } else if (a[i] !== b[i]) {
      return true
    }
  }
  return false
}
function getColumns(e, subMenu) {
  var ary = copyJSON(e)
  var savedList = JSON.parse(localStorage.getItem(subMenu + "CustomColumns"))
  if (savedList) {
    for (var l of ary) {
      if (!l.fixed) {
        l.isChecked = false
      }
    }
    for (var i of savedList) {
      for (var j of ary) {
        if (i == j.label) {
          j.isChecked = true
        }
      }
    }
  }
  var savedListTag = JSON.parse(localStorage.getItem(subMenu + "CustomColumnsTag"))
  if (savedListTag) {
    savedListTag.forEach((item) => {
      ary.forEach((items) => {
        if (items.label === item) {
          items.isChecked = true
        }
      })
    })
  }
  return ary
}

function getArrayAttrs(arr, attr, attr1) {
  let obj = {},
    attrArr = `${attr}Arr`,
    attrArr1 = `${attr1}Arr`

  obj[attrArr] = []
  obj[attrArr1] = []

  if (arr && arr.length > 0) {
    arr.map((item, index) => {
      obj[attrArr][index] = item[attr]
      obj[attrArr1][index] = item[attr1]
    })
  }

  return obj
}

function pickData(data, field) {
  return data.map(function (item) {
    var result = {}
    for (var key in item) {
      if (Reflect.has(item, key) && field.indexOf(key) !== -1) {
        result[key] = item[key]
      }
    }
    return result
  })
}

function inputDetection(n, o, max = 99999999, decimal = 2) {
  if (!max) {
    max = 99999999
  }
  //不为负数
  if (n < 0 && n != "") {
    return o
  }
  if (n == ".") {
    return "0."
  }
  //必须是数字
  if (isNaN(n)) {
    return o
  }
  if (o == "0" && n != "0." && n != "") {
    return Number(n)
  }
  var str = n ? n.toString() : ""
  var num = str.split(".")
  if (decimal == 0 && n.toString().indexOf(".") != -1) {
    return num[0]
  }
  if (o == "0.") {
    if (Number(n) >= 1) {
      return "0." + Number(n)
    }
    return n
  }
  if (Number(n) > max) {
    return o
  }
  if (num[1] && num[1].length > decimal) {
    return Number(n).toFixed(decimal)
  }
  if (num[0].length > 1 && num[0].indexOf("0") == 0) {
    return Number(n)
  }
  return n
}

function DownLoad(res, name) {
  var data = res?.data
  if (data === undefined || data === null) {
    return
  }
  if (res.data.type.indexOf("json") != -1) {
    var reader = new FileReader()
    reader.readAsText(res.data)
    reader.onload = (e) => {
      var err = JSON.parse(e.target.result)
      var errmsg = err.msg
      if (err.msg.indexOf("not find") != -1) {
        errmsg = "Could not find file"
      }
      PacvueMessage({
        message: '<p style="font-size:12px">' + errmsg + "</p>",
        type: "error",
        customClass: "pacvue-message-error"
      })
    }
    return false
  }
  var blob = new Blob([res.data])
  var downloadElement = document.createElement("a")
  var href = window.URL.createObjectURL(blob) // 创建下载的链接
  downloadElement.href = href
  downloadElement.download = name // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
  window.URL.revokeObjectURL(href) // 释放掉blob对象
  return true
}

function getPSTdate(date) {
  var d
  if (date) {
    d = new Date(date)
  } else {
    d = new Date()
  }
  var day = d.getUTCDate()
  if (day < 10) {
    day = "0" + day
  }
  var month = d.getUTCMonth() + 1
  if (month < 10) {
    month = "0" + month
  }
  var year = d.getUTCFullYear()
  var newdate = year + "-" + month + "-" + day + " " + d.getUTCHours() + ":" + d.getMinutes()
  let hours = dayjs().isDST() ? 8 : 7
  let PST_Date = dayjs(new Date(newdate).getTime() - hours * 3600 * 1000).format("YYYY/MM/DD")
  return PST_Date
}
function formatDate(time, type) {
  if (!time) return "--"
  let dateStr = ""
  switch (type) {
    case "month":
      dateStr = dayjs(time).format("MMM-YYYY")
      break
    case "date":
      dateStr = dayjs(time).format("MM/DD/YYYY")
      break
    case "minute":
      dateStr = dayjs(time).format("MM/DD/YYYY HH:mm")
      break
    case "second":
      dateStr = dayjs(time).format("MM/DD/YYYY HH:mm:ss")
      break
  }
  return dateStr
}
function uniqTextarea(textarea) {
  var a = textarea.split("\n")
  var b = []
  for (var i of a) {
    if (i.trim() != "") {
      b.push(i.trim())
    }
  }
  return uniq(b)
}
function uniq(array) {
  var temp = [] //一个新的临时数组
  for (var i = 0; i < array.length; i++) {
    if (i == 0) {
      temp.push(array[i])
    } else {
      if (temp.indexOf(array[i]) == -1) {
        temp.push(array[i])
      }
    }
  }
  return temp
}
function valueEquals(a, b) {
  if (a === b) return true
  if (!(a instanceof Array)) return false
  if (!(b instanceof Array)) return false
  if (a.length !== b.length) return false
  for (let i = 0; i !== a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

function getCalendarContainer() {
  return (triggerNode) => triggerNode.parentNode
}
const SECRET_KEY = CryptoJS.enc.Utf8.parse("1997101019920515")
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse("1992111819980101")
//back up ("1992082719901227")
function encrypt(data) {
  if (typeof data === "object") {
    try {
      // eslint-disable-next-line no-param-reassign
      data = JSON.stringify(data)
    } catch (error) {
      console.log("encrypt error:", error)
    }
  }
  const dataHex = CryptoJS.enc.Utf8.parse(data)
  const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString()
}

function decrypt(data) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(data)
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}
function countryFlagSrc(dc) {
  return "https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/country/" + dc + ".png"
}

function replaceOldImage(data) {
  if (!data || data == null || data == "null") {
    return "https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/no-img.svg"
  }
  var src = data.replace("http://ecx.images-amazon.com", "https://m.media-amazon.com")
  return src
}

function getMarketMinandMaxBid(obj) {
  var bidArr = {
    MX: ["0.1/20000", "0.1/20000", "0.15/20000", "0.1/20000", "5/20000"],
    UK: ["0.02/1000", "0.1/31", "0.15/31", "0.02/1000", "1/1000"],
    DE: ["0.02/1000", "0.1/39", "0.15/39", "0.02/1000", "1/1000"],
    CA: ["0.02/1000", "0.1/49", "0.15/49", "0.02/1000", "1/1000"],
    AU: ["0.1/1410", "0.1/70", "0.15/70", "0.2/1000", "1/1000"],
    US: ["0.02/1000", "0.1/49", "0.25/49", "0.02/1000", "1/1000"],
    FR: ["0.02/1000", "0.1/39", "0.15/39", "0.02/1000", "1/1000"],
    ES: ["0.02/1000", "0.1/39", "0.15/39", "0.02/39", "1/1000"],
    IT: ["0.02/1000", "0.1/39", "0.15/39", "0.02/39", "1/1000"],
    NL: ["0.02/1000", "0.1/39", "", "0.02/1000", "1/1000"],
    JP: ["2/100000", "10/7760", "15/7760", "2/100000", "100/100000"],
    AE: ["0.24/184", "0.4/184", "", "0.24/3670", "1/3670"],
    BR: ["0.07/3700", "0.53/200", "", "0.07/3700", "2/3700"],
    SG: ["0.02/1100", "0.14/100", "0.2/1400", "0.14/1410", "4/1410"],
    SE: ["0.18/9300", "0.9/500", "1.3/500", "0.18/1000", "1/1000"],
    IN: ["1/5000", "1/500", "1.5/500", "1/5000", "4/5000"],
    PL: ["0.04/2000", "0.2/200", "0.3/200", "0.02/1000", "1/1000"],
    TR: ["0.05/2500", "0.2/200", "0.3/200", "0.05/2500", "1.85/2500"],
    EG: ["0.15/5.5", "0.7/400", "1/400", "0.15/7400", "5/7400"],
    SA: ["0.10/3670", "0.4/184", "0.6/184", "0.1/3670", "4/3670"],
    BE: ["0.02/1000", "0.10/39", "0.15/39", "0.02/1000", "1/1000"]
  }
  var marketCode = obj.countryCode ? obj.countryCode : "US"
  var type = obj.campaignType ? obj.campaignType : "sp"
  var costType = obj.costType ? obj.costType : ""
  var is1p = typeof obj.is1p == "undefined" || type != "sd" ? "" : obj.is1p
  var typeNUm = {
    sp: 0,
    sb: 1,
    sbv: 2,
    sdcpctrue: 3,
    sdcpcfalse: 4,
    sdvcpmtrue: 5,
    sdvcpmfalse: 5
  }
  var num = bidArr[marketCode][typeNUm[type + costType + is1p]]
  if (!num) {
    return ""
  }
  var minmaxArr = num.split("/")
  var min = Number(minmaxArr[0])
  var max = Number(minmaxArr[1])
  return {
    max,
    min
  }
}

// 用户埋点，平台字段转换判断
const platformNameChange = (name, targetProduct) => {
  if (name == "product") {
    return "ads amazon"
  } else if (name == "dsa") {
    return "mt 360"
  } else if (name.includes("commerce")) {
    if (targetProduct == "") {
      return "commerce amazon"
    } else {
      return "commerce walmart"
    }
  } else {
    return `ads ${name}`
  }
}

export async function getPltAnnouncement(userInfo) {
  if (!userInfo) return
  let region = ""
  let url = ""
  if (isEU) {
    url = "https://api-eu.pacvue.com/admin-middle-api-eu/announcement/query"
    region = "EU"
  } else if (isCN) {
    region = "CN"
    url = "https://api.pacvue.cn/admin-middle-api-cn/announcement/query"
  } else {
    region = "US"
    url = "https://api.pacvue.com/admin-middle-api-us/announcement/query"
  }
  const res = await request({
    url: url,
    method: "get",
    headers: {
      region
    },
    data: {}
  })
  if (!res) return
  const storeKey = `Global_${userInfo.userId}_announceMsgId`
  let msgId = localStorage.getItem(storeKey)
  if (msgId != res?.id) {
    let content = res.content.replace(/\n/g, "<br/>")
    localStorage.setItem(storeKey, res.id)
    PacvueMessageBox.confirm(content, res.title, {
      confirmButtonText: "OK",
      dangerouslyUseHTMLString: true,
      showCancelButton: false,
      customStyle: { "max-width": "700px" },
      type: "warning"
    })
  }
}

export async function jumpToRetailDashboard(appname, options, targetProduct) {
  const DOMAIN_MAPPER = isDev ? DOMAIN_MAPPER_TEST : DOMAIN_MAPPER_PROD
  const productLine = localStorage.getItem("productline")
  const currPathPrefix = DOMAIN_MAPPER[productLine]?.pathPrefix || ""
  // 外部 (例如 ShareTag 进入 ShareCenter) 未处理的逻辑在这里二次处理
  if (["retailer"].includes(appname)) {
    targetProduct = DOMAIN_MAPPER[appname].pathPrefix
    appname = DOMAIN_MAPPER[appname].subDomain
  }
  const origin = window.location.origin
  const hostname = window.location.hostname.toLowerCase()
  options = options || {}
  const redirect = options.redirect
  const payload = options.payload
  const tagId = options.tagId
  const parentTagId = options.parentTagId
  const params = commonJS.encrypt(
    JSON.stringify({
      ...(redirect ? { redirect } : {}),
      ...(tagId ? { tagId } : {}),
      ...(parentTagId ? { parentTagId } : {})
    })
  )
  // 定义一个已经到了同域名环境下的平台 跳转需要特殊处理
  try {
    const searchParams = new URLSearchParams()
    // 白名单以及没有上线域名统一的平台，都需要注入鉴权码，确保目标平台正确拿到 token
    if (isLocal || appname === "dsa" || isWhiteLabel || !options.isDomainUnified) {
      const authcode = await getCrossAuthCode()
      searchParams.append("authcode", authcode)
    }
    searchParams.append("crossNav", "1")
    searchParams.append("query", productLine)
    searchParams.append("params", params)
    appname === "dsa" && searchParams.append("referrer", origin)
    redirect && searchParams.append("redirect", redirect)
    payload && searchParams.append("payload", payload) // 必须在后续跳转落地后保持的 query 参数
    tagId && searchParams.append("tagId", tagId)
    parentTagId && searchParams.append("parentTagId", parentTagId)
    if (hostname == "localhost") {
      if (!["retailer"].includes(appname)) {
        useAnalyticsTrack("Switch to Other Platforms Success", { category: "Login", "Platform Type": platformNameChange(appname, targetProduct) })
      }
      window.open(`http://localhost:3001?${searchParams.toString()}`)
    } else {
      let domain = isCN ? "cn" : "com"
      if (isEU) {
        const euSuffix = appname === "product" ? "europe" : "-eu"
        appname = appname + euSuffix
      }
      if (appname.startsWith("dsa")) {
        appname = "dsa"
        domain = "com"
      }
      let url = ""
      if (isWhiteLabel) {
        if (window.location.host.indexOf("oceanwing.com.cn") !== -1 && appname === "dsa") {
          url = `https://dsa.pacvue.${domain}/?${searchParams.toString()}`
        } else if (window.location.host.indexOf("console.commerce.next14.com") !== -1) {
          // all => commerce / others
          url = `${window.location.protocol}//console.${targetProduct === "commerce" ? "commerce2" : "commerce"}.next14.com/${targetProduct || ""}?${searchParams.toString()}`
        } else if (window.location.host.indexOf("console.commerce2.next14.com") !== -1 && appname !== "dsa") {
          // commerce => others
          url = `${window.location.protocol}//console.commerce.next14.com/${targetProduct || ""}?${searchParams.toString()}`
        } else {
          if (["amazon-dsp", "commerce-walmart"].includes(targetProduct) || ["product", "commerce"].includes(appname)) {
            url = `${window.location.protocol}//${window.location.host}/${targetProduct || ""}?${searchParams.toString()}`
          } else {
            url = `${window.location.protocol}//${appname}.pacvue.${domain}/${targetProduct || ""}?${searchParams.toString()}`
          }
        }
      } else if (appname.indexOf("https") !== -1) {
        url = `${appname}/${targetProduct || ""}?${searchParams.toString()}`
      } else {
        url = `https://${appname}.pacvue.${domain}/${targetProduct || ""}?${searchParams.toString()}`
      }
      if (!["retailer"].includes(appname)) {
        useAnalyticsTrack("Switch to Other Platforms Success", { category: "Login", "Platform Type": platformNameChange(appname, targetProduct) })
      }
      window.open(url, options.mode)
      return
    }
  } catch (err) {
    useAnalyticsTrack("Switch to Other Platforms Fail", { category: "Login", "Platform Type": platformNameChange(appname, targetProduct), "error type": err?.msg || "" })
  }
}

export default {
  formatExchangeRate,
  copyJSON,
  formatData,
  formatFullData,
  formatMoney,
  formatMoney1,
  formatSalesMoney,
  formatSpendsMoney,
  formatPercent100,
  toThousands,
  getTimeByZoon,
  formatPercent1,
  colorclass,
  dataISminus,
  colorclass1,
  equalArr,
  getColumns,
  parseDate,
  getArrayAttrs,
  pickData,
  inputDetection,
  DownLoad,
  getPSTdate,
  uniqTextarea,
  formatDate,
  valueEquals,
  getCalendarContainer,
  formatPercent,
  formatPercentToe,
  uniq,
  c,
  encrypt,
  decrypt,
  countryFlagSrc,
  replaceOldImage,
  getMarketMinandMaxBid
}
