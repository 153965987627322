import { PacvueMessage } from "@pacvue/element-plus"
import * as proIndexConfig from "./proindex-config"
import { dayjs } from "@pacvue/element-plus"
import CryptoJS from "crypto-js"
// import { useUserStore } from "@/store"
import { unref, nextTick } from "vue"
import { useProIndex, PacvueFormatNumber } from "./useProindex"
// 货币符号转换两种应用方式：
// 1. v-currency="当前行的currency",比如v-currency="scope.row.currency"
// 2. this.formatExchangeRate('带货币符号的数据', 需要转换成的currency)
// 注意!!!  v-currency不可在同一标签上跟v-if v-else同时使用，会失效 或者 列表等发生怪异现象比如列对应数据错位
function formatExchangeRate(oldV, currency = "euro") {
  let mapping = { euro: "€" }
  let reg = /\€/
  let sessioncurrency = sessionStorage.getItem("currency")
  if (sessioncurrency) {
    mapping = JSON.parse(sessioncurrency)
    let values = Object.values(mapping).join("")
    reg = new RegExp("[" + values + "]")
  }
  if (reg.test(oldV)) {
    return oldV.replace(reg, mapping[currency && currency.toLocaleLowerCase()] || "€")
  }
  return oldV
}

function copyJSON(str) {
  var a = JSON.stringify(str)
  var b = JSON.parse(a)
  return b
}

function formatData(num, type = "", symbol = "$", { isFixedDigit = true, fixedNum, formatMode } = {}) {
  var { toThousandsxFields, formatPercentFields, fixd2NumFields } = proIndexConfig
  var lowerType = type ? type.toLowerCase() : ""
  if (isNaN(num)) {
    return lowerType.indexOf("budget") != -1 ? "--" : ""
  } else {
    if (lowerType) {
      if (lowerType == "spend" || lowerType == "spent") {
        return formatSpendsMoney(num, symbol, { isFixedDigit })
      } else if (lowerType.indexOf("budget") != -1) {
        return num == 0 ? "--" : formatBudgetMoney(num, symbol)
      } else if (
        [
          "sales",
          "othersales",
          "pickupndeliverysales",
          "shippingsales",
          "clicksales",
          "pickupndeliveryclicksales",
          "shippingclicksales",
          "relatedclicksales",
          "pickupndeliveryrelatedclicksales",
          "shippingrelatedclicksales",
          "brandclicksales",
          "offlinesales",
          "pickupndeliverybrandclicksales",
          "shippingbrandclicksales",
          "sameeansales"
        ].includes(lowerType)
      ) {
        return formatSalesMoney(num, symbol, { isFixedDigit })
      } else if (
        lowerType.indexOf("acos") != -1 ||
        lowerType.indexOf("sov") != -1 ||
        [
          "ctr",
          "cvr",
          "clickcvr",
          "dsfrequency",
          "shareofshelf",
          "top3rate",
          "top3times",
          "adsfrequency",
          "ofprofilespend",
          "ofcampaignspend",
          "percent",
          "peroftotalspends",
          "peroftotalsales",
          "othersalespercent",
          "newtobrandorderspercentage",
          "newtobrandsalespercentage",
          "newtobrandunitsorderedpercentage",
          "newtobrandorderrate",
          "impressionshare",
          "lostimpressionsharebybudget",
          "lostimpressionsharebyadrank",
          "acosbenchmark",
          "clickacos",
          "sameskucvr",
          "cvrimpr",
          "topofsearchis",
          "trendingrate",
          "adrate",
          "videocompleterate",
          "cpcpercent"
        ].includes(lowerType) ||
        formatPercentFields.includes(lowerType) ||
        formatMode == "percent"
      ) {
        return formatPercent100(num, symbol)
      } else if (
        [
          "impression",
          "impressions",
          "click",
          "clicks",
          "conversion",
          "conversions",
          "conversionsotherean",
          "inventorycount",
          "saleunits",
          "clicksaleunits",
          "totalorders",
          "reviewscount",
          "averagerating",
          "avgposition",
          "avgorganicposition",
          "avgadposition",
          "instock",
          "orders",
          "clickorders",
          "searchvolume",
          "newtobrandunitsordered",
          "newtobrandorders",
          "viewimpression",
          "sameskuconversions",
          "sameskusaleunits",
          "cumulativeReach",
          "avgImpressionsFrequency",
          "brandedSearch",
          "videocompleteviews",
          "addtocart",
          "detailpageviews",
          "newtobranddetailpageview"
        ]
          .map((e) => e.toLocaleLowerCase())
          .includes(lowerType) ||
        toThousandsxFields.includes(lowerType) ||
        fixd2NumFields.includes(lowerType) ||
        formatMode == "toThousands"
      ) {
        return toThousandsx(num, symbol, fixedNum)
      } else if (["newtobrandroas", "newtobrandcpa"].includes(lowerType)) {
        return formatMoney1(num, symbol, { isFixedDigit })
      } else {
        return formatMoney(num, symbol, { isFixedDigit })
      }
    } else {
      return formatMoney(num, symbol, { isFixedDigit })
    }
  }
}

function c(str) {
  var a = JSON.stringify(str)
  var b = JSON.parse(a)
  return b
}

function formatFullData(num, type, symbol = "$", { isFixedDigit = true, fixedNum, formatMode } = {}) {
  var { toThousandsxFields, formatPercentFields, fixd2NumFields } = proIndexConfig
  if (isNaN(num)) {
    return ""
  }
  if (num == 0) {
    return formatData(num, type, symbol)
  } else {
    let lowerType = type ? type.toLowerCase() : ""
    if (lowerType) {
      if (
        lowerType.indexOf("acos") != -1 ||
        lowerType.indexOf("sov") != -1 ||
        lowerType.indexOf("rate") != -1 ||
        [
          "ctr",
          "cvr",
          "clickcvr",
          "dsfrequency",
          "shareofshelf",
          "top3rate",
          "adsfrequency",
          "ofprofilespend",
          "ofcampaignspend",
          "percent",
          "buyboxpct",
          "availabilitypct",
          "buyboxpctlastweek",
          "availabilitypctlastweek",
          "othersalespercent",
          "newtobrandorderspercentage",
          "newtobrandsalespercentage",
          "newtobrandunitsorderedpercentage",
          "newtobrandorderrate",
          "impressionshare",
          "lostimpressionsharebybudget",
          "lostimpressionsharebyadrank",
          "acosbenchmark",
          "clickacos",
          "sameskucvr",
          "cvrimpr",
          "topofsearchis",
          "videocompleterate",
          "cpcpercent"
        ].includes(lowerType) ||
        formatPercentFields.includes(lowerType) ||
        formatMode == "percent"
      ) {
        return formatPercent100(num, symbol)
      } else if (
        [
          "impression",
          "impressions",
          "click",
          "clicks",
          "conversion",
          "conversions",
          "conversionsotherean",
          "inventorycount",
          "saleunits",
          "clicksaleunits",
          "totalorders",
          "reviewscount",
          "averagerating",
          "avgposition",
          "avgorganicposition",
          "avgadposition",
          "instock",
          "orders",
          "clickorders",
          "searchvolume",
          "newtobrandunitsordered",
          "newtobrandorders",
          "viewimpression",
          "sameskuconversions",
          "sameskusaleunits",
          "glanceviews",
          "videocompleteviews",
          "addtocart",
          "detailpageviews",
          "newtobranddetailpageview",
          "brandedsearch"
        ].includes(lowerType) ||
        lowerType.indexOf("units") != -1 ||
        toThousandsxFields.includes(lowerType) ||
        fixd2NumFields.includes(lowerType) ||
        formatMode == "toThousands"
      ) {
        return toThousands(num, symbol, fixedNum)
      } else if (["newtobrandroas", "newtobrandcpa"].includes(lowerType)) {
        return formatMoney1(num, symbol, { isFixedDigit })
      } else {
        return symbol + toThousands(num, symbol, fixedNum, { isFixedDigit: symbol ? true : false })
      }
    } else {
      return symbol + toThousands(num, symbol, fixedNum, { isFixedDigit: symbol ? true : false })
    }
  }
}

function formatSpendsMoney(money = 0, num = "$", { isFixedDigit = true } = {}) {
  var type = num

  // if (num && num == "￥") {
  //   return type + toThousands(Math.round(new Number(money)));
  // }
  var absVal = Math.abs(money)
  if (absVal >= 1000000) {
    return type + toThousands((money / 1000000).toFixed(1), num, 1, { isFixedDigit }) + "M"
  } else if (absVal >= 10000) {
    return type + toThousands(new Number(money / 1000).toFixed(1), num, 1, { isFixedDigit }) + "K"
  } else if (absVal > 1) {
    return type + toThousands(new Number(money).toFixed(1), num, 1, { isFixedDigit })
  } else {
    return type + toThousands(new Number(money).toFixed(2), num, 2, { isFixedDigit })
  }
}

function formatPercentOther100(v = 0) {
  v = new Number(v)
  if (v == 100) {
    return PacvueFixedDigit({ value: v, digitCount: 0 }) + "%"
    // return v.toFixed(0) + "%"
  } else {
    return PacvueFixedDigit({ value: v, digitCount: 1 }) + "%"
    //return v.toFixed(1) + "%"
  }
}

function formatBudgetMoney(money = 0, symbol = "$", { isFixedDigit = true } = {}) {
  var type = symbol
  var absVal = Math.abs(money)
  if (symbol && symbol == "￥") {
    return type + toThousands(Math.round(new Number(money)))
  }
  if (absVal >= 1000000) {
    return type + toThousands((money / 1000000).toFixed(1), symbol, 1, { isFixedDigit }) + "M"
  } else if (absVal > 10000) {
    return type + toThousands(new Number(money / 1000).toFixed(1), symbol, 1, { isFixedDigit }) + "K"
  } else if (absVal > 1) {
    return type + toThousands(new Number(money).toFixed(1), symbol, 1, { isFixedDigit })
  } else {
    return type + toThousands(new Number(money).toFixed(2), symbol, 2, { isFixedDigit })
  }
}
// function formatBudgetMoney(t = 0, symbol = '$') {
//   if (isNaN(t)) {
//     return symbol + '0';
//   }
//   var num = new Number(t);
//   num = parseFloat(num);
//   num = num.toString().split('.'); // 分隔小数点
//   var arr = num[0].split('').reverse(); // 转换成字符数组并且倒序排列
//   var res = [];
//   for (var i = 0, len = arr.length; i < len; i++) {
//     if (i % 3 === 0 && i !== 0) {
//       res.push(','); // 添加分隔符
//     }
//     res.push(arr[i]);
//   }
//   res.reverse(); // 再次倒序成为正确的顺序
//   // 小数不用管了
//   // if (num[1]) {
//   //   // 如果有小数的话添加小数部分
//   //   res = res.join('').concat('.' + num[1]);
//   // } else {
//   //   res = res.join('');
//   // }
//   return symbol + res.join('');
// }
function formatSalesMoney(money = 0, num = "$", { isFixedDigit = true } = {}) {
  var type = num

  // if (num && num == '￥') {
  //   return type + toThousands(Math.round(new Number(money)))
  // }
  var absVal = Math.abs(money)
  if (absVal >= 1000000) {
    return type + toThousands((money / 1000000).toFixed(1), num, 1, { isFixedDigit }) + "M"
  } else if (absVal >= 10000) {
    return type + toThousands(new Number(money / 1000).toFixed(1), num, 1, { isFixedDigit }) + "K"
  } else if (absVal > 10) {
    return type + toThousands(new Number(money).toFixed(1), num, 1, { isFixedDigit })
  } else if (absVal > 0.1) {
    return type + toThousands(new Number(money).toFixed(1), num, 1, { isFixedDigit })
  } else {
    return type + toThousands(new Number(money).toFixed(2), num, 2, { isFixedDigit })
  }
}

function formatPercent100(v = 0, symbol = "$") {
  v = new Number(v)
  if (v >= 100) {
    //return v.toFixed(0) + "%"
    return PacvueFixedDigit({ value: v, currency: symbol, digitCount: 0 }) + "%"
  } else {
    return PacvueFixedDigit({ value: v, currency: symbol, digitCount: 2 }) + "%"
    // return (symbol == "€" ? v.toFixed(2).replace(".", ",") : v.toFixed(2)) + "%"
  }
}

function formatPercent(v = 0, symbol = "$") {
  v = new Number(v)
  return PacvueFixedDigit({ value: v, currency: symbol, digitCount: 2 }) + "%"
  // return (symbol == "€" ? v.toFixed(2).replace(".", ",") : v.toFixed(2)) + "%"
}
function formatPercentToe(value = 0) {
  let str = ""
  if (value > 0) {
    let p = Math.floor(Math.log(value) / Math.LN10)
    let val = (value / 10 ** p).toFixed(1)
    str = val + "e" + p + "%"
  } else {
    value = Number(value.toString().substring(1, value.length))
    let p = Math.floor(Math.log(value) / Math.LN10)
    let val = (value / 10 ** p).toFixed(1)
    str = "-" + val + "e" + p + "%"
  }
  return str
}

function toThousands(price = 0, currency, digitCount = 2, { isFixedDigit = false } = {}) {
  let result = price
  digitCount = digitCount || 2
  var divisor = Math.pow(10, digitCount)
  price = Math.round(price * divisor) / divisor
  if (currency === "euro" || currency === "€") {
    result = price.toLocaleString("de-DE")
  } else {
    result = price.toLocaleString()
  }
  if (isFixedDigit) {
    result = PacvueFixedDigit({ value: result, currency, digitCount })
  }
  return result
}

function formatMoneyRoas(money = 0, type = "$", { isFixedDigit = true } = {}) {
  // ********日元货币+cn域名特殊算法废弃********
  // if (type && type == '￥' && location.href.lastIndexOf('cn') == -1) {
  //   return (new Number(money) * 100).toFixed(1) + '%'
  // }
  return type + toThousands(new Number(money).toFixed(2), type, 2, { isFixedDigit })
}

function marketCode(city) {
  if (city == "US") {
    return "$"
  } else if (city == "MX") {
    return "MXN"
  } else if (city == "ES" || city == "DE" || city == "FR" || city == "IT" || city == "NL" || city == "BE") {
    return "€"
  } else if (city == "JP") {
    return "￥"
  } else if (city == "UK" || city == "GB") {
    return "£"
  } else if (city == "AE") {
    return "AED"
  } else if (city == "IN") {
    return "₹"
  } else if (city == "SA") {
    return "SAR"
  } else if (city == "BR") {
    return "R$"
  } else if (city == "CA") {
    return "C$"
  } else if (city == "SG") {
    return "S$"
  } else if (city == "SE") {
    return "Kr"
  } else if (city == "AU") {
    return "A$"
  } else if (city == "EG") {
    return "EGP"
  } else if (city == "PL") {
    return "PLN"
  } else if (city == "TR") {
    return "TL"
  } else {
    return "$"
  }
}

function formatMoney(money = 0, num = "$", { isFixedDigit = true, formatMode = "auto" } = {}) {
  var type = num
  money = PacvueFormatNumber({ val: money })
  if (num && num == "￥" && formatMode === "auto") {
    return type + toThousands(new Number(money.toFixed(2)), num, 2, { isFixedDigit })
  }

  var absVal = Math.abs(money)
  if (absVal > 1000000) return type + toThousands((money / 1000000).toFixed(2), num, 2, { isFixedDigit }) + "M"
  else if (absVal > 10000) return type + toThousands(new Number(money / 1000).toFixed(2), num, 2, { isFixedDigit }) + "K"
  else return type + toThousands(new Number(money).toFixed(2), num, 2, { isFixedDigit })
}

function formatMoney1(money, type = "$", { isFixedDigit = true, formatMode = "auto" } = {}) {
  money = PacvueFormatNumber({ val: money })
  if (type && type == "￥" && formatMode === "auto") {
    //return type + toThousands(Math.round(new Number(money)))
    return type + toThousands(new Number(money.toFixed(2)), type, 2, { isFixedDigit })
  }
  return type + toThousands(new Number(money).toFixed(2), type, 2, { isFixedDigit })
}

function toThousandsx(t = 0, type = "$", fixedNum, { isFixedDigit = false } = {}) {
  t = new Number(t)
  var num = 0
  var absVal = Math.abs(t)
  if (absVal >= 1000000) {
    return toThousands((t / 1000000).toFixed(1), type, 1, { isFixedDigit: type ? true : isFixedDigit }) + "M"
  } else if (absVal > 10000) {
    return toThousands((t / 1000).toFixed(1), type, 1, { isFixedDigit: type ? true : isFixedDigit }) + "K"
  } else {
    if (fixedNum) {
      return toThousands(parseFloat(t.toFixed(fixedNum || 0)), type, fixedNum || 0, { isFixedDigit: type ? true : isFixedDigit })
    }
    return toThousands(t.toFixed(0), type)
  }
}

/**
 * 时间格式化通用方案（按照用户的偏好设置处理）
 * @param {String} date 传入的时间 所有new Date可以转化的格式均可以
 * @param {String} type 传入的时间格式
 * @param {String} timezone 传入的时间戳 true：按照偏好转化 false：电脑时区 String：按照传入的字符串
 * @param {String} InputString 当传入的时间是字符串 如：24/01/2023 等 可以传入DD/MM/YYYY以此实现特定格式的格式化
 * @param {Boolean} NoPerformence 不使用偏好设置
 * @param {Boolean} TimezoneOffset 是否存使用时区偏移量。前端创建的时间传后端是字符串的类型 后端转utc存在时区的偏差，但是后端自己生成的时间本来就是UTC时区 所以不存在 这是需要设置为true
 */
function parseDate(date, type, timezone, InputString, NoPerformence, TimezoneOffset) {
  let tzMap = {
    "America/Los_Angeles": "PST8PDT",
    MST: "MST7MDT",
    "America/Eastern": "EST5EDT",
    "America/Central": "CST6CDT",
    "Europe/Paris": "Europe/Paris",
    "Europe/London": "Europe/London",
    "China/Beijing": "Asia/Shanghai",
    "Asia/Tokyo": "Asia/Tokyo",
    "Australia/Sydney": "Australia/Sydney",
    "Asia/Kolkata": "Asia/Kolkata",
    "Asia/Dubai": "Asia/Dubai"
  }
  if (!date) return "--"
  let time = date
  let realFormatType = type
  // var userStore = useUserStore()
  if (window.perSetting) {
    // 判断时间是否是long时间戳

    if (typeof date === "number" || typeof date === "object" || (typeof date === "string" && (date.indexOf("-") !== -1 || date.indexOf("/") !== -1))) {
      time = date
      // return dayjs(date).format(type)
    } else {
      // 判断字符串类型的情况下是否包含/- 包含就是直接格式化 不包含就是转化成num
      time = Number(time)
    }
    if (!NoPerformence) {
      if (window.perSetting.dateFormat == 0) {
        // mmdd
        realFormatType = realFormatType.replace("DD/MM", "MM/DD")
        realFormatType = realFormatType.replace("DD-MM", "MM-DD")
      } else if (window.perSetting.dateFormat == 1) {
        realFormatType = realFormatType.replace("MM/DD", "DD/MM")
        realFormatType = realFormatType.replace("MM-DD", "DD-MM")
      }
    }
  }
  let newDate = dayjs(time, InputString)
  if (typeof date === "string" && (date.length < 13 || date.indexOf("-") !== -1 || date.indexOf("/") !== -1)) {
    newDate = dayjs(time, InputString)
  } else {
    TimezoneOffset ? (newDate = dayjs(Number(time))) : (newDate = dayjs(Number(time)).add(new Date(Number(time)).getTimezoneOffset() / 60, "hours"))
  }

  //timezone判断 true false String
  const timeZoneChangeName = {
    "America/Eastern": "America/New_York",
    "America/Central": "America/Chicago",
    "China/Beijing": "Asia/Shanghai"
  }
  if (!timezone) {
    return newDate.format(realFormatType)
  } else if (timezone === true) {
    //按照个性化设置格式
    return newDate.tz(timeZoneChangeName[window.perSetting?.timeZone] || window.perSetting?.timeZone).format(realFormatType)
    // return newDate.tz(window.perSetting?.timeZone).format(realFormatType)
  } else if (typeof timezone === "string") {
    return newDate.tz(tzMap[timezone] || timezone).format(realFormatType)
  }

  return newDate.format(realFormatType)
}

function getTimeByZoon(format = "YYYY-MM-DD HH:MM:SS") {
  let zoon = "EST"
  let dateStr = ""
  switch (zoon) {
    case "EST":
      dateStr = getESTTime(format)
      break
  }
  return dateStr
}

function getESTTime(formatStr) {
  let hours = dayjs().isDST() ? 4 : 5,
    est_date = dayjs.utc().subtract(hours, "hours").format(formatStr)
  return est_date
}

function formatPercent1(v = 0, symbol) {
  v = new Number(v)
  return PacvueFixedDigit({ value: v, currency: symbol, digitCount: 1 }) + "%"
  //return v.toFixed(1) + "%"
}

function colorclass1(j, val) {
  var k = j.toLowerCase()
  let arr = [
      "ctr",
      "cvr",
      "click",
      "clicks",
      "spc",
      "sales",
      "relatedclicksales",
      "brandviewsales",
      "viewsales",
      "roas",
      "clickSales",
      "clicksales",
      "clickROAS",
      "clickroas",
      "brandClickSales",
      "brandClickROAS",
      "brandclicksales",
      "brandclickroas",
      "offLineSales",
      "offlinesales",
      "troas",
      "revenue",
      "saleunits",
      "orders",
      "clickorders",
      "organicrevenue",
      "totalorders",
      "brandHaloClickSales",
      "brandhaloclicksales",
      "brandHaloClickROAS",
      "brandhaloclickroas",
      "shareOfShelf",
      "shareofshelf",
      "organicSOV",
      "organicsov",
      "spPaidSOV",
      "sppaidsov",
      "adsFrequency",
      "adsfrequency",
      "avgPosition",
      "avgposition",
      "avgOrganicPosition",
      "avgorganicposition",
      "avgAdPosition",
      "avgadposition",
      "avgPaidPosition",
      "avgpaidposition",
      "topThreeRate",
      "topthreerate",
      "pageOneRate",
      "pageonerate",
      "sbPaidSOV",
      "sbpaidsov",
      "paidSOV",
      "paidsov",
      "conversion"
    ],
    arr1 = ["acos", "cpa", "cpc", "cpm", "clickAcos", "clickacos", "clickorders", "brandClickACOS", "brandclickacos", "tacos", "brandHaloClickACOS", "brandhaloclickacos", "spend"]
  if (val == 0) {
    return "gray"
  } else if (arr.includes(k)) {
    return dataISminus(val) ? "red" : "green"
  } else if (arr1.includes(k)) {
    return dataISminus(val) ? "green" : "red"
  } else {
    return "gray"
  }
}
function dataISminus(val) {
  if (val <= 0) {
    return true
  } else {
    return false
  }
}

function colorclass(j, val) {
  j = (j || "").toLowerCase()
  if (
    j.indexOf("sales") != -1 ||
    j.indexOf("roas") != -1 ||
    j.indexOf("position") != -1 ||
    j.indexOf("paidsov") != -1 ||
    j.indexOf("orders") != -1 ||
    ["ctr", "cvr", "click", "clicks", "spc", "revenue", "saleunits", "organicrevenue", "shareofshelf", "organicsov", "adsfrequency", "topthreerate", "pageonerate", "conversion"].includes(j)
  ) {
    return val == 0 ? "gray" : val < 0 ? redType(val) : "green"
  } else if (j.indexOf("acos") != -1 || ["cpa", "cpc", "cpm"].includes(j)) {
    return val == 0 ? "gray" : val < 0 ? "green" : redType(val)
  } else {
    return "gray"
  }
}
function redType(val) {
  let newVal = val > 0 ? val : 0 - val
  if (newVal <= 50) {
    return "yellow"
  } else {
    return "red"
  }
}
var hours = Array(
  "12AM",
  "01AM",
  "02AM",
  "03AM",
  "04AM",
  "05AM",
  "06AM",
  "07AM",
  "08AM",
  "09AM",
  "10AM",
  "11AM",
  "12PM",
  "01PM",
  "02PM",
  "03PM",
  "04PM",
  "05PM",
  "06PM",
  "07PM",
  "08PM",
  "09PM",
  "10PM",
  "11PM"
)

function getHourAb(h) {
  if (h >= 24) h = 0
  return hours[h]
}

function equalArr(a, b) {
  //增加判断，数组定义后为空是null清空的数组相等判断
  if ((a == null && b && b.length == 0) || (a && a.length == 0 && b == null)) {
    return false
  }
  if (!b || !a) {
    return true
  }
  if (a.length != b.length) {
    return true
  }
  for (var i = 0; i < a.length; i++) {
    if (a[i] instanceof Array && b[i] instanceof Array) {
      if (equalArr(a[i], b[i])) return true
    } else if (a[i] !== b[i]) {
      return true
    }
  }
  return false
}
function getColumns(e, subMenu) {
  var ary = copyJSON(e)
  var savedList = JSON.parse(localStorage.getItem(subMenu + "CustomColumns"))
  if (savedList) {
    for (var l of ary) {
      if (!l.fixed) {
        l.isChecked = false
      }
    }
    for (var i of savedList) {
      for (var j of ary) {
        if (i == j.label) {
          j.isChecked = true
        }
      }
    }
  }
  var savedListTag = JSON.parse(localStorage.getItem(subMenu + "CustomColumnsTag"))
  if (savedListTag) {
    savedListTag.forEach((item) => {
      ary.forEach((items) => {
        if (items.label === item) {
          items.isChecked = true
        }
      })
    })
  }
  return ary
}

function getArrayAttrs(arr, attr, attr1) {
  let obj = {},
    attrArr = `${attr}Arr`,
    attrArr1 = `${attr1}Arr`

  obj[attrArr] = []
  obj[attrArr1] = []

  if (arr && arr.length > 0) {
    arr.map((item, index) => {
      obj[attrArr][index] = item[attr]
      obj[attrArr1][index] = item[attr1]
    })
  }

  return obj
}

function pickData(data, field) {
  return data.map(function (item) {
    var result = {}
    for (var key in item) {
      if (Reflect.has(item, key) && field.indexOf(key) !== -1) {
        result[key] = item[key]
      }
    }
    return result
  })
}

function inputDetection(n, o, max = 99999999, decimal = 2) {
  if (!max) {
    max = 99999999
  }
  //不为负数
  if (n < 0 && n != "") {
    return o
  }
  if (n == ".") {
    return "0."
  }
  //必须是数字
  if (isNaN(n)) {
    return o
  }
  if (o == "0" && n != "0." && n != "") {
    return Number(n)
  }
  var str = n ? n.toString() : ""
  var num = str.split(".")
  if (decimal == 0 && n.toString().indexOf(".") != -1) {
    return num[0]
  }
  if (o == "0.") {
    if (Number(n) >= 1) {
      return "0." + Number(n)
    }
    return n
  }
  if (Number(n) > max) {
    return o
  }
  if (num[1] && num[1].length > decimal) {
    return Number(n).toFixed(decimal)
  }
  if (num[0].length > 1 && num[0].indexOf("0") == 0) {
    return Number(n)
  }
  return n
}

function DownLoad(res, name) {
  const data = res?.data
  if (data === undefined || data === null) {
    return
  }
  if (res.data.type.indexOf("json") != -1) {
    var reader = new FileReader()
    reader.readAsText(res.data)
    reader.onload = (e) => {
      var err = JSON.parse(e.target.result)
      var errmsg = err.msg
      if (err.msg.indexOf("not find") != -1) {
        errmsg = "Could not find file"
      }
      PacvueMessage({
        message: '<p style="font-size:12px">' + errmsg + "</p>",
        type: "error",
        customClass: "pacvue-message-error"
      })
    }
    return false
  }
  var blob = new Blob([res.data])
  var downloadElement = document.createElement("a")
  var href = window.URL.createObjectURL(blob) // 创建下载的链接
  downloadElement.href = href
  downloadElement.download = name // 下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() // 点击下载
  document.body.removeChild(downloadElement) // 下载完成移除元素
  window.URL.revokeObjectURL(href) // 释放掉blob对象
  return true
}

function getPSTdate(date) {
  var d
  if (date) {
    d = new Date(date)
  } else {
    d = new Date()
  }
  var day = d.getUTCDate()
  if (day < 10) {
    day = "0" + day
  }
  var month = d.getUTCMonth() + 1
  if (month < 10) {
    month = "0" + month
  }
  var year = d.getUTCFullYear()
  var newdate = year + "-" + month + "-" + day + " " + d.getUTCHours() + ":" + d.getMinutes()
  let hours = dayjs().isDST() ? 8 : 7
  let PST_Date = dayjs(new Date(newdate).getTime() - hours * 3600 * 1000).format("YYYY/MM/DD")
  return PST_Date
}
function formatDate(time, type) {
  if (!time) return "--"
  let dateStr = ""
  switch (type) {
    case "date":
      dateStr = dayjs(time).format("MM/DD/YYYY")
      break
    case "minute":
      dateStr = dayjs(time).format("MM/DD/YYYY HH:mm")
      break
    case "second":
      dateStr = dayjs(time).format("MM/DD/YYYY HH:mm:ss")
      break
  }
  return dateStr
}
function uniqTextarea(textarea) {
  var a = textarea.split("\n")
  var b = []
  for (var i of a) {
    if (i.trim() != "") {
      b.push(i.trim())
    }
  }
  return uniq(b)
}
function uniq(array) {
  var temp = [] //一个新的临时数组
  for (var i = 0; i < array.length; i++) {
    if (i == 0) {
      temp.push(array[i])
    } else {
      if (temp.indexOf(array[i]) == -1) {
        temp.push(array[i])
      }
    }
  }
  return temp
}
function valueEquals(a, b) {
  if (a === b) return true
  if (!(a instanceof Array)) return false
  if (!(b instanceof Array)) return false
  if (a.length !== b.length) return false
  for (let i = 0; i !== a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

function getCalendarContainer() {
  return (triggerNode) => triggerNode.parentNode
}
const SECRET_KEY = CryptoJS.enc.Utf8.parse("1997101019920515")
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse("1992111819980101")
//back up ("1992082719901227")
function encrypt(data) {
  if (typeof data === "object") {
    try {
      // eslint-disable-next-line no-param-reassign
      data = JSON.stringify(data)
    } catch (error) {
      console.log("encrypt error:", error)
    }
  }
  const dataHex = CryptoJS.enc.Utf8.parse(data)
  const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString()
}

function decrypt(data) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(data)
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

function replaceOldImage(data) {
  if (!data || data == null || data == "null") {
    return "https://pacvue-public-doc.s3.us-west-2.amazonaws.com/oss/pacvue-cdn/vue3/no-img.svg"
  }
  var src = data.replace("http://ecx.images-amazon.com", "https://m.media-amazon.com")
  return src
}

function getMarketMinandMaxBid(obj) {
  var bidArr = {
    MX: ["0.1/20000", "0.1/20000", "128/800000", "0.15/20000", "80/800000", "0.1/20000", "5/20000", "50/5000"],
    UK: ["0.02/1000", "0.1/31", "4/5000", "0.15/31", "8/5000", "0.02/1000", "1/1000"],
    DE: ["0.02/1000", "0.1/39", "5/5000", "0.15/39", "7/5000", "0.02/1000", "1/1000"],
    CA: ["0.02/1000", "0.1/49", "11/5000", "0.15/49", "11/5000", "0.02/1000", "1/1000", "11/1000"],
    AU: ["0.1/1410", "0.1/70", "14/2800", "0.15/70", "20/2800", "0.2/1000", "1/1000"],
    US: ["0.02/1000", "0.1/49", "8/5000", "0.25/49", "12/5000", "0.02/1000", "1/1000", "10/1000"],
    FR: ["0.02/1000", "0.1/39", "4/5000", "0.15/39", "8/5000", "0.02/1000", "1/1000"],
    ES: ["0.02/1000", "0.1/39", "4/5000", "0.15/39", "7/5000", "0.02/39", "1/1000"],
    IT: ["0.02/1000", "0.1/39", "4/5000", "0.15/39", "6/5000", "0.02/39", "1/1000"],
    NL: ["0.02/1000", "0.1/39", "6/1560", "0.15/39", "8/1560", "0.02/1000", "1/1000"],
    JP: ["2/100000", "10/7760", "560/310400", "15/7760", "800/310400", "2/100000", "100/100000"],
    AE: ["0.24/184", "0.4/184", "44/7360", "0.6/184", "28/7360", "0.24/3670", "1/3670"],
    BR: ["0.07/3700", "0.53/200", "37/8000", "0.8/25000", "53/8000", "0.07/3700", "2/3700", "9/900"],
    SG: ["0.02/1100", "0.14/100", "6/4000", "0.2/1400", "29/4000", "0.14/1410", "4/1410"],
    SE: ["0.18/9300", "0.9/500", "36/20000", "1.3/500", "276/20000", "0.18/1000", "1/1000"],
    IN: ["1/5000", "1/500", "84/80000", "1.5/500", "200/80000", "1/5000", "4/5000"],
    PL: ["0.04/2000", "0.2/200", "92/8000", "0.3/200", "180/8000", "0.02/1000", "1/1000"],
    TR: ["0.05/2500", "0.2/200", "74/8000", "0.3/200", "106/8000", "0.05/2500", "1.85/2500"],
    EG: ["0.15/5.5", "0.7/400", "160/16000", "1/400", "300/16000", "0.15/7400", "5/7400"],
    SA: ["0.10/3670", "0.4/184", "28/7360", "0.6/184", "40/7360", "0.1/3670", "4/3670"],
    BE: ["0.02/1000", "0.10/39", "4/1560", "0.15/39", "6/1560", "0.02/1000", "1/1000"]
  }
  var marketCode = obj.countryCode ? obj.countryCode : "US"
  var type = obj.campaignType ? obj.campaignType : "sp"
  var costType = obj.costType ? obj.costType : ""
  var is1p = typeof obj.is1p == "undefined" || type != "sd" ? "" : obj.is1p
  var typeNUm = {
    sp: 0,
    sb: 1,
    sbcpc: 1,
    sbvcpm: 2,
    sbv: 3,
    sbvcpc: 3,
    sbvvcpm: 4,
    sdcpctrue: 5,
    sdcpcfalse: 5,
    sdvcpmtrue: 6,
    sdvcpmfalse: 6,
    stv: 7
  }
  var num = bidArr[marketCode][typeNUm[type + costType + is1p]]
  if (!num) {
    return ""
  }
  var minmaxArr = num.split("/")
  var min = Number(minmaxArr[0])
  var max = Number(minmaxArr[1])
  return {
    max,
    min
  }
}
function marketAddress(city) {
  switch (city) {
    case "AU":
      return "com.au"
    case "BR":
      return "com.br"
    case "CA":
      return "ca"
    case "CN":
      return "cn"
    case "FR":
      return "fr"
    case "DE":
      return "de"
    case "IN":
      return "in"
    case "IT":
      return "it"
    case "JP":
      return "co.jp"
    case "MX":
      return "com.mx"
    case "NL":
      return "nl"
    case "ES":
      return "es"
    case "TR":
      return "com.tr"
    case "UK":
      return "co.uk"
    case "US":
      return "com"
    case "AE":
      return "ae"
    case "SA":
      return "sa"
    case "SG":
      return "sg"
    case "SE":
      return "se"
    case "EG":
      return "eg"
    case "PL":
      return "pl"
    case "BE":
      return "com.be"
    default:
      return "com"
  }
}
function marketCodeBudget(city) {
  if (city == "US") {
    return "$"
  } else if (city == "ES" || city == "DE" || city == "FR" || city == "IT" || city == "NL" || city == "BE") {
    return "€"
  } else if (city == "JP") {
    return "￥"
  } else if (city == "UK") {
    return "£"
  } else if (city == "AU") {
    return "A$"
  } else if (city == "CA") {
    return "C$"
  } else if (city == "MX") {
    return "MXN"
  } else if (city == "AE") {
    return "AED"
  } else if (city == "IN") {
    return "₹"
  } else if (city == "SA") {
    return "SAR"
  } else if (city == "BR") {
    return "R$"
  } else if (city == "SG") {
    return "S$"
  } else if (city == "SE") {
    return "Kr"
  } else if (city == "EG") {
    return "EGP"
  } else if (city == "PL") {
    return "PLN"
  } else if (city == "TR") {
    return "TL"
  } else {
    return "$"
  }
}
//一位小数，小于0.1特殊处理，高于100%显示100%
function formatPercent2(v, filedName, rowData, showCampar, currencySymbol) {
  v = new Number(v)
  var orderBy = ""
  var changeCompar = filedName + "Compare"
  var changeVal = filedName + "Change"

  var generalFiledName = ["avgPosition", "avgOrganicPosition", "AvgOrganicPosition", "avgPaidPosition", "reviews", "stars", "price", "Spend"]
  // 超过100不显示100%
  let not100Show = ["freq"]
  var newchangeVal = rowData[changeVal] || 0
  var colorStatus = ""
  if (newchangeVal >= 0) {
    orderBy = "up"
    colorStatus = "green"
  } else if (newchangeVal < 0) {
    orderBy = "down"
    colorStatus = "red"
  }
  var value, oldValue, chageValue
  // 不需要百分比的value
  if (generalFiledName.indexOf(filedName) != -1) {
    value = v
  } else {
    if (v < 0.1 && v != 0) {
      value = "<0.1%"
    } else if (v > 100 && not100Show.indexOf(filedName) < 0) {
      value = "100%"
    } else {
      value = v.toFixed(1) + "%"
    }
  }
  let changeComparNum = rowData[changeCompar] || 0
  if (generalFiledName.indexOf(filedName) != -1) {
    oldValue = changeComparNum
  } else {
    if (changeComparNum < 0.1 && changeComparNum != 0) {
      oldValue = "<0.1%"
    } else {
      oldValue = changeComparNum.toFixed(1) + "%"
    }
  }
  var mathChangeVal = Math.abs(newchangeVal)
  if (mathChangeVal < 0.1 && mathChangeVal != 0) {
    chageValue = "<0.1%"
  } else {
    chageValue = mathChangeVal.toFixed(1) + "%"
  }
  var campalHtml = ""
  if (!showCampar) {
    campalHtml = ""
  } else {
    // if(filedName=='price'){
    campalHtml = `<div><div title="${oldValue}" class="${colorStatus}-item" style="display:inline-block;">${chageValue}<span class="icon-gray-${orderBy} glyphicon glyphicon-arrow-${orderBy}"></span></div><?div>`
    // }else{
    //     var campalHtml = `<div title="${oldValue}" class="${colorStatus}-item">${chageValue}<span class="icon-gray-${orderBy} glyphicon glyphicon-arrow-${orderBy}"></span></div>`
    // }
  }
  if (filedName == "price" || filedName == "Spend") {
    return `<span>${currencySymbol}${value}</span>` + campalHtml
  } else {
    return `<span>${value}</span>` + campalHtml
  }
}
function countryFlagSrc(dc) {
  return "https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/country/" + dc + ".png"
}

const pacvueUtilMemoryCache = {
  _dc: undefined,
  profileThreshold: 50,
  JapanRoasIsRevert: false, //Japan ROAS是否需要特殊转换
  canFeadToday: true //是否开启isFeadToday功能
} //  全局缓存需要缓存的信息
/**
 * 格式化指标
 * @param {格式化数据json} totalData
 * @param {字段名称，如果传了只格式化单key的数据} filedName
 * @param {货币符号,$,￥} monenyCode
 * @param {是否需要格式化对比数据,默认flase} showCampar
 * @param {total数据个格式化类型，Product，SellerProduct} totalType
 */
function getPacvueFormatProIndex(
  totalData,
  filedName,
  showCampar,
  monenyCode,
  totalType,
  from,
  metric,
  { customFormatUtil = { formatData: null, formatFullData: null }, isUseOld = true, ignoreFields = [] } = {}
) {
  var { toThousandsxFields, formatPercentFields, fixd2Fields, fixd2NumFields } = proIndexConfig
  if (isUseOld && window.productline != "amazon" && window.productline != "doordash") {
    var { getPacvueFormatProIndexOld } = useProIndex({
      formatMoneyRoas,
      formatdailyBudget,
      toThousands,
      toThousandsx,
      formatPercent,
      formatMoney,
      formatMoney1,
      formatSpendsMoney,
      formatSalesMoney,
      formatPercentint,
      formatPercent1,
      formatPercent100,
      proIndexConfig
    })
    return getPacvueFormatProIndexOld(totalData, filedName, showCampar, monenyCode, totalType, from, metric)
  }
  var result = {}
  var filedVal = totalData[filedName] || 0
  var filedValPrev = totalData[filedName + "Compar"] || totalData[filedName + "Compare"] || totalData[filedName + "Comapre"] || totalData[filedName + "Compared"] || 0
  var filedValChange = totalData[filedName + "Change"]
  monenyCode = monenyCode || "$"
  var formatDataFn = customFormatUtil?.formatData || formatData
  var formatFullDataFn = customFormatUtil?.formatFullData || formatFullData
  if (filedName != undefined) {
    if (metric) {
      result[filedName] = formatFullDataFn(filedVal, filedName, monenyCode)
      if (showCampar) {
        result[filedName + "Prev"] = formatFullDataFn(filedValPrev, filedName, monenyCode)
      }
    } else {
      result[filedName] = formatDataFn(filedVal, filedName, monenyCode)
      if (showCampar) {
        result[filedName + "Prev"] = formatDataFn(filedValPrev, filedName, monenyCode)
      }
    }
    if (showCampar) {
      if (from) {
        filedValChange > 1000 ? (result[filedName + "Change"] = formatPercentint(filedValChange)) : (result[filedName + "Change"] = formatPercent1(filedValChange))
      } else {
        result[filedName + "Change"] = formatPercent(filedValChange)
      }
    }
    return result
  }
  //如果是total数据,新逻辑修改
  var totalRows = {}
  var numReg = /^(-)?\d+(\.(\d+))?$/

  for (var propKey in totalData) {
    var propValue = totalData[propKey]
    var propValuePrev = totalData[propKey + "Compar"] || totalData[propKey + "Compare"] || totalData[propKey + "Comapre"] || totalData[propKey + "Compared"]
    var propValueChange = totalData[propKey + "Change"]
    if (propValue === undefined) {
      continue
    }
    if (Array.isArray(ignoreFields) && ignoreFields.includes(propKey)) {
      //忽略处理的字段
      totalRows[propKey] = propValue
      continue
    }
    if (propKey.endsWith("Change")) {
      continue
    } else if (propKey.endsWith("Compar") || propKey.endsWith("Compare") || propKey.endsWith("Comapre") || propKey.endsWith("Compared")) {
      continue
    }
    if (typeof propValue == "number" && numReg.test(propValue)) {
      if (metric) {
        totalRows[propKey] = formatFullDataFn(propValue, propKey, monenyCode)
        totalRows[propKey + "Prev"] = formatFullDataFn(propValuePrev, propKey, monenyCode)
      } else {
        totalRows[propKey] = formatDataFn(propValue, propKey, monenyCode)
        totalRows[propKey + "Prev"] = formatDataFn(propValuePrev, propKey, monenyCode)
      }

      totalRows[propKey + "Change"] = formatPercent(propValueChange)
    }
  }
  return totalRows
}
/**
 * 指标格式化，返回确切的值
 * @param {String} filedName 字段名称,如果不传格式化整个属性中为number的属性字段
 * @param {Object} [option={}]
 * @param {String} option.filedName 字段名称，可以不传，就是格式化全部
 * @param {Object} option.rowData 需要处理的数据
 * @param {Boolean} option.showCampar 是否显示对比数据
 * @param {Boolean} option.metric 是否显示fullData
 * @param {String} option.monenyCode 货币符号
 * @param {Object<{formatData:Function,formatFullData:Function}>} option.customFormatUtil 自定义格式化方法
 * @param {Boolean} option.isUseOld 是否使用之前的格式化方法，默认为true
 */
function pacvueProIndexRender({
  filedName,
  rowData = {},
  showCampar = false,
  monenyCode = "$",
  from,
  metric,
  isUseOld = true,
  customFormatUtil = { formatData: null, formatFullData: null },
  ignoreFields = []
} = {}) {
  monenyCode = monenyCode || "$"
  //var originalVal = rowData[filedName] || 0
  var totalRows = getPacvueFormatProIndex(rowData, filedName, showCampar, monenyCode, undefined, from, metric, { customFormatUtil, isUseOld, ignoreFields })
  var totalVal = totalRows[filedName]
  if (filedName === undefined) {
    return totalRows
  }
  return totalVal
}
function formatPercentint(v, symbol) {
  v = v || 0
  v = new Number(v)
  return PacvueFixedDigit({ value: v, currency: symbol, digitCount: 0 }) + "%"
  //return v.toFixed(0) + "%"
}
//dailyBudget 后台返回-100，则前端不显示
function formatdailyBudget(money, type = "$", digitCount) {
  money = money || 0
  if (money < 0) {
    return ""
  }
  if (type && type == "￥") {
    return type + toThousands(Math.round(new Number(money)))
  }
  if (digitCount == "floor") {
    return type + toThousands(Math.floor(new Number(money)))
  }
  digitCount = digitCount != undefined ? digitCount : 2
  return type + toThousands(new Number(money).toFixed(digitCount))
}
function marketDateFormat(city) {
  if (city == "US" || city == "CA" || city == "MX" || city == "IN") {
    return "MM/DD/YYYY"
  } else if (city == "UK" || city == "FR" || city == "IT" || city == "AU" || city == "BR" || city == "NL" || city == "SG" || city == "PL" || city == "TR" || city == "BE") {
    return "DD/MM/YYYY"
  } else if (city == "ES" || city == "DE" || city == "SE" || city == "JP" || city == "AE" || city == "SA" || city == "EG") {
    return "YYYY/MM/DD"
  } else {
    return "MM/DD/YYYY"
  }
}

// 格式化share页面日期区间传值
function formatShareDateRange(dateIndex) {
  let today = new Date()
  today.setHours(0, 0, 0, 0)

  let yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)
  yesterday.setHours(0, 0, 0, 0)

  let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
  let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  let last7day = today.getTime() - 6 * 24 * 60 * 60 * 1000
  let last14day = today.getTime() - 13 * 24 * 60 * 60 * 1000
  let last30day = today.getTime() - 29 * 24 * 60 * 60 * 1000
  let last60day = today.getTime() - 59 * 24 * 60 * 60 * 1000
  let lastExclude7day = today.getTime() - 8 * 24 * 60 * 60 * 1000
  let lastExclude14day = today.getTime() - 15 * 24 * 60 * 60 * 1000
  let lastExclude30day = today.getTime() - 31 * 24 * 60 * 60 * 1000
  let lastExclude60day = today.getTime() - 61 * 24 * 60 * 60 * 1000
  let lastExclude2day = today.getTime() - 2 * 24 * 60 * 60 * 1000
  let lastWeekEnd = new Date(dayjs().subtract(1, "weeks").endOf("week").format())
  lastWeekEnd.setHours(0, 0, 0, 0)

  let dateRangeArray = [
    {
      id: "1",
      name: "Yesterday",
      value: [yesterday, yesterday]
    },
    { id: "2", name: "Last 7 Days", value: [new Date(last7day), today] },
    {
      id: "3",
      name: "Last 7 days (Exclude latest 2 days)",
      value: [new Date(lastExclude7day), new Date(lastExclude2day)]
    },
    { id: "4", name: "Last Week", value: [new Date(dayjs().subtract(1, "week").startOf("week")), lastWeekEnd] },
    { id: "5", name: "This Week", value: [new Date(dayjs().startOf("week")), today] },
    { id: "6", name: "Last 2 Weeks", value: [new Date(dayjs().subtract(2, "week").startOf("week")), lastWeekEnd] },
    { id: "7", name: "Last 4 Weeks", value: [new Date(dayjs().subtract(4, "week").startOf("week")), lastWeekEnd] },
    { id: "8", name: "Last 30 Days", value: [new Date(last30day), today] },
    {
      id: "9",
      name: "Last 30 days (Exclude latest 2 days)",
      value: [new Date(lastExclude30day), new Date(lastExclude2day)]
    },
    {
      id: "10",
      name: "Last Month",
      value: [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)]
    },
    { id: "11", name: "This Month", value: [thisMonthStart, today] },
    { id: "12", name: "Year to Date", value: [new Date(today.getFullYear(), 0, 1), today] },
    { id: "13", name: "Last 60 Days", value: [new Date(last60day), today] },
    {
      id: "14",
      name: "Last 60 days (Exclude latest 2 days)",
      value: [new Date(lastExclude60day), new Date(lastExclude2day)]
    },
    {
      id: "15",
      name: "Last Year",
      value: [new Date(dayjs().subtract(1, "year").startOf("year")), new Date(dayjs().subtract(1, "year").endOf("year"))]
    },
    {
      id: "16",
      name: "Last 14 Days",
      value: [new Date(last14day), today]
    },
    {
      id: "17",
      name: "Last 14 days (Exclude latest 2 days)",
      value: [new Date(lastExclude14day), new Date(lastExclude2day)]
    },
    {
      id: "18",
      name: "Last 12 months",
      value: [new Date(dayjs().subtract(11, "month").startOf("month")), today]
    },
    { id: "0", name: "Custom", value: [] }
  ]

  return dateRangeArray.find((i) => i.id === dateIndex).value
}
const getPermission = function (key) {
  if (!window.permissionObj) {
    return 2
  } else if (window.permissionObj[key] === undefined) {
    return 2
  } else {
    return Number(window.permissionObj[key])
  }
}
const PacvueUUid = (size = 21) => {
  let urlAlphabet = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict"
  let id = ""
  let i = size
  while (i--) {
    id += urlAlphabet[(Math.random() * 64) | 0]
  }
  return id
}
const __TEMP_PAGE_KEY__ = "__temp_page_query__"
const PacvueGetPageQuery = ({ query, isCompression = true }) => {
  let routeQuery = null
  var getCompressionQuey = (query) => {
    var tempJSON = null
    try {
      var tempStr = sessionStorage.getItem(query)
      if (tempStr) {
        tempJSON = JSON.parse(tempStr)
      }
      return tempJSON
    } catch (ex) {
      console.warn(ex)
    }
    return tempJSON
  }
  if (query) {
    if (isCompression === true) {
      return getCompressionQuey(query)
    }
    try {
      routeQuery = JSON.parse(decrypt(query))
    } catch (ex) {
      if (isCompression === "auto") {
        //如果报错 了，可能是需要取压缩过的值
        routeQuery = getCompressionQuey(query)
      }
    }
  }
  return routeQuery
}
const PacvueSetPageQuery = ({ query, isCompression = true }) => {
  if (isCompression) {
    var uniqueId = new Date().getTime() //PacvueUUid()
    sessionStorage.setItem(uniqueId, JSON.stringify(query))
    return uniqueId
  }
  let routeQuery = encrypt(JSON.stringify(query))
  return routeQuery
}
const getUserId = function () {
  var id = localStorage.getItem("uid") || ""
  if (sessionStorage.getItem("useInfo")) {
    id = JSON.parse(sessionStorage.getItem("useInfo")).userId
  }
  return id
}

function getMarketMinandMaxBudget(obj) {
  var budgetArr = {
    US: ["1/1000000", "1/1000000", "1/50000", "1/1000000", "100/20000000", undefined, "20/50000"],
    CA: ["1/1000000", "1/1000000", "1/50000", "1/1000000", "100/20000000", undefined, "20/27500"],
    UK: ["1/1000000", "1/1000000", "1/50000", "1/1000000", "100/20000000"],
    DE: ["1/1000000", "1/1000000", "1/50000", "1/1000000", "100/20000000"],
    FR: ["1/1000000", "1/1000000", "1/50000", "1/1000000", "100/20000000"],
    IT: ["1/1000000", "1/1000000", "1/50000", "1/1000000", "100/20000000"],
    ES: ["1/1000000", "1/1000000", "1/50000", "1/1000000", "100/20000000"],
    IN: ["50/21000000", "50/21000000", "50/5000000", "100/21000000", "5000/200000000", "500/21000000"],
    JP: ["100/21000000", "100/21000000", "100/5000000", "100/21000000", "10000/21000000"],
    CN: ["1/21000000", "", "", "1/21000000", "100/200000000"],
    AU: ["1.4/1500000", "1.4/1500000", "1.4/1500000", "1/1500000", "141/28000000"],
    MX: ["1/21000000", "1/21000000", "1/21000000", "1/21000000", "1000/200000000", undefined, "100/125000"],
    UAE: ["4/3700000", "4/3700000", "4/3700000", "4/3700000", "367/74000000"],
    SA: ["4/3700000", "4/3700000", "4/3700000", "4/3700000", "367/74000000"],
    BR: ["1.32/5300000", "1.32/5300000", "1.32/5300000", "4/3700000", "367/74000000", undefined, "20/22500"],
    NL: ["1/1000000", "1/1000000", "1/1000000", "4/3700000", "367/74000000"],
    SG: ["1.39/1300000", "4/1300000", "4/1300000", "4/3700000", "367/74000000"],
    TR: ["2/2500000", "2/2700000", "2/2700000", "2/2700000", "250/53000000"],
    PL: ["2/2000000", "1/1000000", "1/1000000", "2/2400000", "200/47000000"],
    SE: ["9/9300000", "1/1000000", "1/1000000", "9/9400000", "900/187000000"],
    EG: ["7/7400000", "7/7600000", "7/7600000", "7/7600000", "750/151000000"],
    BE: ["1/1000000", "1/1000000", "1/1000000", "1/1000000", "100/20000000"]
  }
  var marketCode = obj.countryCode ? obj.countryCode : "US"
  var type = obj.campaignType ? obj.campaignType.toLowerCase() : "sp"
  var lifetime = obj.totalbudget && obj.totalbudget > 0 ? "life" : ""
  var is1p = typeof obj.is1p == "undefined" || type != "sd" ? "" : obj.is1p
  var INvendor = marketCode == "IN" && obj.is1p == true ? "vendor" : ""
  var typeNUm = {
    sp: 0,
    sdfalse: 1,
    sdtrue: 2,
    sb: 3,
    sblife: 4,
    sbvendor: 5,
    stv: 6
  }
  var num = budgetArr[marketCode][typeNUm[type + lifetime + is1p + INvendor]]
  if (!num) {
    return ""
  }
  var minmaxArr = num.split("/")
  var min = Number(minmaxArr[0])
  var max = Number(minmaxArr[1])
  return {
    max,
    min
  }
}
export function setQuery(query) {
  let routeQuery = encrypt(JSON.stringify(query))
  return routeQuery
}
export function goUrl({ path, query, storeType = "url", queryName = "query" }) {
  if (window.routerObj) {
    path = window.routerObj.resolve({ path }).href || ""
  } else {
    path = path || ""
  }

  if (path.indexOf("/Budget/") == 0 && path != "/Budget/BudgetDashboard") {
    window.open(path + "?" + queryName + "=" + setQuery(query))
    return
  }
  window.open(path + "?" + queryName + "=" + setQuery(query))
}
/**
 * 强制性保留几位小数位数
 * @param {Object} [option={}]
 * @param {String|Number} option.value 需要处理的数据
 * @param {String} option.currency 货币符号
 * @param {number} [option.digitCount=2] 强制性保留的小数点位数
 */
export function PacvueFixedDigit({ value, currency, digitCount = 2 } = {}) {
  var numSplitCharInfo = PacuveGetNumSplitChar({ currency })
  var digitSplitStr = numSplitCharInfo.digitSplit
  var digitReg = new RegExp("(?<=\\" + digitSplitStr + ")(\\d+)")
  var result = value
  if (typeof value == "number" || value instanceof Number) {
    if (currency === "euro" || currency === "€") {
      result = value.toLocaleString("de-DE", { maximumFractionDigits: digitCount, minimumFractionDigits: digitCount })
    } else {
      result = value.toLocaleString(undefined, { maximumFractionDigits: digitCount, minimumFractionDigits: digitCount })
    }
  }
  var matchList = result.match(digitReg) || []
  var digitStr = matchList[0] || ""
  var digitSize = digitStr.length
  if (digitStr.length < digitCount) {
    //当前小数位不够需要补零
    var supplementCount = digitCount - digitStr.length
    var zeroList = new Array(supplementCount)
    zeroList.fill("0")
    result += (digitSize ? "" : digitSplitStr) + zeroList.join("")
  }
  return result
}
/**
 * 获取数字千位分隔符
 * @param {Object} [option={}]
 * @param {String} option.currency 货币符号
 * @returns Object<{thousandsSplit:String,digitSplit:String}>
 */
export function PacuveGetNumSplitChar({ currency } = {}) {
  var testNum = 1000.8
  var splitList = [".", ","]
  var comprStr = testNum.toLocaleString()
  if (currency === "euro" || currency === "€") {
    comprStr = testNum.toLocaleString("de-DE")
  }
  var zhStr = testNum.toLocaleString("zh")
  var thousandsSplit = ","
  var digitSplit = "."
  if (zhStr.length != comprStr.length) {
    //如果不等，则不存在分隔符，取相对应位置的分隔符
    var testNumStr = testNum + ""
    if (comprStr.length == testNumStr.length) {
      var digitIndex2 = testNumStr.indexOf(".")
      digitSplit = comprStr.charAt(digitIndex2)
      thousandsSplit = "" //不存在千位分隔符
    }
  } else {
    var thousandsIndex = zhStr.indexOf(",")
    var digitIndex = zhStr.indexOf(".")
    thousandsSplit = comprStr.charAt(thousandsIndex)
    digitSplit = comprStr.charAt(digitIndex)
  }
  //为了确保获取其他字符，做兼容处理
  if (!splitList.includes(thousandsSplit) && thousandsSplit !== "") {
    thousandsSplit = ","
  }
  if (!splitList.includes(digitSplit)) {
    thousandsSplit = "."
  }
  return {
    thousandsSplit,
    digitSplit
  }
}
export function PacvueGetMainBodyScroller() {
  return unref(window.scrollerMain)
}
export function PacvueMainBodyScrollTop({ top = 0 } = {}) {
  var scroller = PacvueGetMainBodyScroller()
  if (scroller) {
    scroller?.setScrollTop(top)
  }
}

function PacvueTranslateT(arr) {
  let newArr = []

  arr.forEach((v) => {
    let title = v.title
    v.title = $t(title)
    newArr.push(v)
  })

  return newArr
}
function PacvueGetWeekStartSun(d, market, dateFormat) {
  var marketCode = market || "US"
  // var time = dayjs(d, dateFormat||marketDateFormat(marketCode));
  // return time.week();
  var time = dayjs(d, dateFormat || marketDateFormat(marketCode))
  var FirstOfTime = time.startOf("week")
  var FirstDayOfYear = dayjs(FirstOfTime)
  FirstDayOfYear = FirstDayOfYear.date(4) //4号作为分界点
  FirstDayOfYear = FirstDayOfYear.month(0)
  var interval = 0
  var FirstDayOfWeek = FirstDayOfYear.startOf("week") //第一周的第一天日期
  var days = FirstOfTime.diff(FirstDayOfWeek, "days")
  days += FirstDayOfYear.day()
  let num = Math.floor(days / 7) + 1 - interval
  return num
}
/**
 * @function 将日期装换为通用的日期格式,如果sourceDateFormat，desDateFormat，在跟随market日期格式来
 * @param {Object} [option={}]
 * @param {String|Number} option.date 需要格式化的日期对象
 * @param {String} option.dim 日期类型Day|Week|Month|Quarter|Year
 * @param {String} option.sourceDateFormat 源日期格式
 * @param {String} option.desDateFormat 源日期格式
 * @param {String} option.desDateFormat 导出的日期格式
 * @param {String} option.marketCode 默认的日期格式市场为US,
 * @param {String} option.defaultUserMarketCode 用户的市场dc
 * @returns String
 */
function PacvueTransfromDate({ date, dim, sourceDateFormat, desDateFormat, marketCode = "US", defaultUserMarketCode = "US" } = {}) {
  var dc = defaultUserMarketCode
  //日期需要特殊转换
  var formatDateStr = date
  var dateFormat = sourceDateFormat || marketDateFormat(marketCode || "US")
  desDateFormat = desDateFormat || marketDateFormat(marketCode || "US")
  try {
    var dateStr = date + ""
    var dateM = dayjs(date, dateFormat)
    if (dateStr.length == dateFormat.length) {
      dateM = dayjs(date, dateFormat)
    }
    if (!dateM.isValid()) {
      dateM = dayjs(date)
    } else {
      dateStr = date + ""
      if (dateM.format(dateFormat) != date) {
        //防止date格式不止日月年
        dateM = dayjs(date)
      }
    }
    if (dim == "Week" || dim == "week") {
      var startOfWeek = dateM.startOf("week")
      formatDateStr = startOfWeek.format(desDateFormat)
      if (formatDateStr != "Invalid date") {
        formatDateStr = $t("amskey2584", [PacvueGetWeekStartSun(formatDateStr, dc, desDateFormat)]) + "," + formatDateStr
      }
    } else if (dim == "Day" || dim == "day") {
      formatDateStr = dateM.format("ddd," + desDateFormat)
    } else if (dim == "Month" || dim == "month") {
      formatDateStr = dateM.format("MMM-YYYY")
    } else if (dim == "Quarter" || dim == "quarter") {
      //formatDateStr = dateM.format(`qQ-YYYY`).toUpperCase()
      formatDateStr = dateM.format(`${$t("q{0}", ["Q"])}-YYYY`).toUpperCase()
    } else if (dim == "Year" || dim == "year") {
      formatDateStr = dateM.format("YYYY")
    }
  } catch (ex) {
    /* empty */
  }
  if (formatDateStr == "Invalid date") {
    formatDateStr = date
  }
  return formatDateStr
}
export function getProIndexCompareState(type, value) {
  var colorMap = {
    gray: " #b2b2b2",
    green: "#28c76f",
    orange: "#fbaf46",
    red: "#ea5455"
  }
  let formatType = type.toLowerCase()
  value = value + ""
  let formatValue = Number(value.replace("%", ""))
  var ascRedField = ["acospercent", "cpapercent", "cpcpercent", "cpc", "cpm", "acos", "tacos", "cpa", "newtobrandacos", "newtobrandacospercent"]
  var ascGrayField = ["spendpercent", "spend", "imp", "impression", "impressions", "totalspendpercent", "campaignspend", "adspend"]

  let orderBy = "right"
  let colorStatus = "gray"
  if (!isNaN(formatValue)) {
    formatValue = PacvueKeepDigit(Number(formatValue), 2)
    if (ascRedField.indexOf(formatType) != -1) {
      //ASC RED
      if (formatValue > 0) {
        colorStatus = "red"
        orderBy = "up"
        if (formatValue < 50) {
          colorStatus = "orange"
        }
      } else if (formatValue < 0) {
        colorStatus = "green"
        orderBy = "down"
      } else {
        colorStatus = "gray"
      }
    } else if (ascGrayField.indexOf(formatType) != -1) {
      //ASC GRAY
      if (formatValue > 0) {
        orderBy = "up"
      } else if (formatValue < 0) {
        orderBy = "down"
      }
    } else {
      //ASC GREEN
      if (formatValue > 0) {
        colorStatus = "green"
        orderBy = "up"
      } else if (formatValue < 0) {
        colorStatus = "red"
        orderBy = "down"
        if (formatValue > -50) {
          colorStatus = "orange"
        }
      } else {
        colorStatus = "gray"
      }
    }
  }
  return {
    dir: orderBy,
    color: colorStatus,
    colorVal: colorMap[colorStatus]
  }
}
export function PacvueKeepDigit(value, digitCount, isIntercept) {
  if (String(value).trim() === "") return ""
  var numVal = Number(value)
  digitCount = digitCount || 0
  if (isNaN(numVal)) {
    return 0
  }
  var divisor = Math.pow(10, digitCount)
  if (isIntercept) {
    return Math.floor(numVal + "e" + digitCount) / divisor
    //return Math.floor(numVal * divisor) / divisor
  } else {
    return Math.round(numVal + "e" + digitCount) / divisor
    // return Math.round(numVal * divisor) / divisor
  }
}

async function usePacvueValidation({ valFnObjs = [], elFormRefs = [], wrapperClass = "" } = {}) {
  // 传参校验
  if (
    !wrapperClass ||
    !Array.isArray(valFnObjs) ||
    valFnObjs.find((fn) => !["[object Function]", "[object AsyncFunction]"].includes(Object.prototype.toString.call(fn.exec)) || (fn.args !== undefined && !Array.isArray(fn.args))) ||
    !Array.isArray(elFormRefs)
  ) {
    throw new Error("Invalid argument!")
  }
  const wrapperEls = document.querySelectorAll(`.${wrapperClass}`)
  let wrapperEl = null
  for (const el of wrapperEls) {
    if (window.getComputedStyle(el).display !== "none") {
      wrapperEl = el
      break
    }
  }
  if (!wrapperEl) {
    throw new Error("Wrapper element not found!")
  }
  const promises = []
  // fn => promise
  if (valFnObjs.length > 0) {
    promises.push(
      ...valFnObjs.map((fn, index) => {
        return new Promise(async (resolve, reject) => {
          const res = fn.exec(...(fn.args || []))
          // 如果返回值自身是 promise
          if (Object.prototype.toString.call(res) === "[object Promise]") {
            try {
              const val = await res
              if (val) {
                resolve(true)
              } else {
                reject("error")
              }
            } catch (err) {
              reject("error")
            }
          } else {
            if (res) {
              resolve(true)
            } else {
              reject("error")
            }
          }
        })
      })
    )
  }
  if (elFormRefs.length > 0) {
    promises.push(...elFormRefs.map((ref) => (ref.value ? ref.value?.validate() : ref?.validate())))
  }
  // 校验结果处理, 获取最顶端报错的表单元素位置
  const results = await Promise.allSettled(promises)
  let flag = false
  await nextTick(() => {
    let deltaTop = Infinity
    let scrollTargetEl = null
    const deltaTopComparison = (el) => {
      const { top } = el.getBoundingClientRect()
      if (top < deltaTop) {
        deltaTop = top
        scrollTargetEl = el
      }
    }
    const rejectedCustomItems = results.filter((res) => res.status === "rejected" && typeof res.reason === "string")
    const rejectedElFormItems = results.filter((res) => res.status === "rejected" && typeof res.reason !== "string")
    if (rejectedCustomItems.length === 0 && rejectedElFormItems.length === 0) {
      flag = true
    } else {
      if (rejectedCustomItems.length > 0) {
        const errEls = wrapperEl.querySelectorAll(".pac-form-error")
        for (const errEl of errEls) {
          const { display, visibility, opacity } = window.getComputedStyle(errEl)
          const { width, height } = errEl.getBoundingClientRect()
          // 若待滚动的元素并没有展示 或 并未在布局中占位, 则绕过
          if (display === "none" || visibility === "hidden" || opacity == "0" || (width === 0 && height === 0)) {
            continue
          }
          deltaTopComparison(errEl)
        }
      }
      if (rejectedElFormItems.length > 0) {
        const errEls = wrapperEl.querySelectorAll(".el-form-item.is-error")
        for (const errEl of errEls) {
          deltaTopComparison(errEl)
        }
      }
      scrollTargetEl && scrollTargetEl.scrollIntoView({ behavior: "smooth" })
      flag = false
    }
  })
  return flag
}

export default {
  formatExchangeRate,
  copyJSON,
  formatData,
  formatFullData,
  marketCode,
  formatMoney,
  formatMoney1,
  formatSalesMoney,
  formatSpendsMoney,
  formatPercent100,
  formatMoneyRoas,
  toThousands,
  getTimeByZoon,
  formatPercent1,
  colorclass,
  dataISminus,
  colorclass1,
  equalArr,
  getColumns,
  parseDate,
  getArrayAttrs,
  pickData,
  inputDetection,
  replaceOldImage,
  getPSTdate,
  uniqTextarea,
  formatDate,
  valueEquals,
  getCalendarContainer,
  formatPercent,
  formatPercentToe,
  uniq,
  c,
  encrypt,
  decrypt,
  formatPercentOther100,
  toThousandsx,
  getHourAb,
  getPacvueFormatProIndex,
  formatdailyBudget,
  marketDateFormat,
  marketAddress,
  marketCodeBudget,
  DownLoad,
  formatPercent2,
  countryFlagSrc,
  pacvueProIndexRender,
  getMarketMinandMaxBid,
  formatShareDateRange,
  getPermission,
  PacvueUUid,
  PacvueGetPageQuery,
  PacvueSetPageQuery,
  getUserId,
  getMarketMinandMaxBudget,
  goUrl,
  PacvueFixedDigit,
  PacuveGetNumSplitChar,
  proIndexConfig,
  PacvueMainBodyScrollTop,
  PacvueTranslateT,
  PacvueTransfromDate,
  PacvueGetWeekStartSun,
  getProIndexCompareState,
  usePacvueValidation
}
