export default {
  routerInterceptors: (to) => {
    return new Promise((res) => {
      // const str = campaignAINewUrl()
      // if (str == "/New" && (to.path == "/Optimization/AutomationAI" || to.path == "/Optimization/AutomationAI/launchAIforCampaigns" || to.path == "/Optimization/AutomationAI/launchAIforTags")) {
      //   if (to.query.query) {
      //     to.fullPath = `/New${to.path}?query=${to.query.query}`
      //     to.path = `/New${to.path}`
      //   } else {
      //     to.fullPath = `/New${to.path}`
      //   }
      // }
      res(true)
    })
  }
}
