<script setup>
import { ElConfigProvider, LOCALE_ZHCN, LOCALE_EN } from "@pacvue/element-plus"
import { provide, reactive, ref, onMounted, computed, unref } from "vue"
import { useUserStore } from "@/store"
import { storeToRefs } from "pinia"
import useFrameStore from "./frameStore.js"
import { useAppTimeout } from "./timeout.js"
import LOCALE_JA from "@pacvue/element-plus/dist/locale/ja"
import * as loadingAnimators from "../assets/global-loading-anim/index.js"
import { isSpWhiteLabel, spWhiteLabelConfig } from "../constants/env"

const frameStore = useFrameStore()

// 配合 v-tooltips 自定义指令相关逻辑
const isTooltipVisible = ref(false)
const virRef = ref()
window.changeVisible = function (target) {
  virRef.value = target
  isTooltipVisible.value = true
}

// 开启自动登出定时触发任务
useAppTimeout()

// 面包屑相关方法注入全局 Context
const updateBreadcrumbLeft = (component) => {
  breadcrumb.BreadcrumbLeft = component
}
const updateBreadcrumbRight = (component) => {
  breadcrumb.BreadcrumbRight = component
}
const setClassName = (className) => {
  breadcrumb.className = className
}
const breadcrumb = reactive({
  HeadCustom: null,
  BreadcrumbLeft: null,
  BreadcrumbRight: null,
  className: "",
  updateBreadcrumbLeft,
  updateBreadcrumbRight,
  setClassName
})
provide("breadcrumb", breadcrumb)

// 组件库国际化注入
const userStore = useUserStore()
let { locale } = storeToRefs(userStore)
const localeMap = {
  ZH: LOCALE_ZHCN,
  EN: LOCALE_EN,
  JA: LOCALE_JA
}
const localeInfo = computed(() => localeMap[unref(locale)] || localeMap["EN"])

// 全局 Loading 相关
const pacvuePromotionLoading = ref(true)
if (window.location.href.lastIndexOf("pacvue") == -1) {
  pacvuePromotionLoading.value = false
}
// const globalLoadingCpn = computed(() => {
//   if (isSpWhiteLabel) {
//     return loadingAnimators[spWhiteLabelConfig.globalLoadingSVG]
//   } else if (pacvuePromotionLoading.value) {
//     return loadingAnimators.Pacvue
//   } else {
//     return loadingAnimators.Common
//   }
// })

onMounted(() => {
  // 操纵调色盘
  const appEl = document.querySelector("#app")
  if (isSpWhiteLabel) {
    spWhiteLabelConfig.topBarBgColor && appEl.style.setProperty("--el-color-bg-head", spWhiteLabelConfig.topBarBgColor)
    spWhiteLabelConfig.topBarTextColor && appEl.style.setProperty("--el-color-font-head", spWhiteLabelConfig.topBarTextColor)
    spWhiteLabelConfig.sideBarBgColor && appEl.style.setProperty("--el-color-bg-menu", spWhiteLabelConfig.sideBarBgColor)
    spWhiteLabelConfig.sideBarBgSubColor && appEl.style.setProperty("--el-color-secondbg-menu", spWhiteLabelConfig.sideBarBgSubColor)
  }
  if (window.location.href.lastIndexOf("pacvue") == -1) {
    if (window.location.href.lastIndexOf("portal.revmo.com") != -1) {
      appEl.style.setProperty("--el-color-bg-head", "#710000")
      appEl.style.setProperty("--el-color-font-head", "#fff")
    } else if (window.location.href.lastIndexOf("ams.revivemedia.us") != -1) {
      appEl.style.setProperty("--el-color-bg-head", "#8E68F4")
      appEl.style.setProperty("--el-color-font-head", "#fff")
    } else if (window.location.href.lastIndexOf("audacy.pacvue") != -1) {
      appEl.style.setProperty("--el-color-bg-head", "#7A3BCB")
      appEl.style.setProperty("--el-color-font-head", "#fff")
    } else if (window.location.href.lastIndexOf("canyonwallinsights") != -1) {
      appEl.style.setProperty("--el-color-bg-head", "#1451FF")
      appEl.style.setProperty("--el-color-font-head", "#fff")
    }
  }
})
</script>

<template>
  <el-config-provider :locale="localeInfo">
    <div id="app" class="pacvue-next">
      <!-- 搭配 v-tooltips 指令 Start -->
      <el-tooltip
        placement="top"
        v-model:visible="isTooltipVisible"
        :popper-options="{
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                adaptive: false,
                enabled: false
              }
            }
          ]
        }"
        :virtual-ref="virRef"
        virtual-triggering
        popper-class="el-tooltipMaxWidth"
      >
        <template #content>
          <span>{{ frameStore.singleTooltip.text }}</span>
        </template>
      </el-tooltip>
      <!-- 搭配 v-tooltips 指令 End -->
      <div id="pacvue-loading">
        <component v-if="isSpWhiteLabel" :is="loadingAnimators[spWhiteLabelConfig.globalLoadingSVG]" />
        <img v-else-if="pacvuePromotionLoading" src="https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/loading.svg" alt="" />
        <img v-else src="https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/loading1.svg" alt="" />
      </div>
      <!-- Copilot Drag & Resize 组件容器, 且鼠标事件穿透 -->
      <div id="copilot-drag-resize-container" class="fixed z-[2000] top-0 right-0 bottom-0 left-0 pointer-events-none overflow-hidden"></div>
      <!-- 主框架 Main (SideBar + HeadBar + Breadcrumb) 路由 -->
      <router-view></router-view>
    </div>
  </el-config-provider>
</template>

<style lang="scss" scoped></style>
<style lang="scss">
:root {
  --page-gap: 16px;
  --page-updown: 16px;
  --page-rightleft: 24px;
}
body {
  font-family: Inter, Helvetica, Arial, sans-serif;
}
#pacvue-loading {
  width: 100%;
  height: 100%;
  z-index: 300000;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  place-items: center center;
}
.dialog-footer .el-button {
  height: 36px;
}
@media print {
  body {
    font-family: Helvetica !important;
    line-height: 0.5 !important;
  }
}
</style>
