export default {
  router: [
    {
      path: "/login/index",
      redirect: "/login"
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../src/view/Login/sign.vue"),
      meta: {
        noRefreshToken: true
      }
    },
    {
      path: "/loginoauth/:name",
      component: () => import("../src/view/Login/loginWithSSO.vue"),
      meta: {
        noRefreshToken: true
      }
    },
    {
      path: "/login/:id",
      component: () => import("../src/view/Login/loginWithSSO.vue"),
      meta: {
        noRefreshToken: true
      }
    },
    {
      path: "/Auth",
      name: "Auth",
      component: () => import("../src/view/Login/Auth.vue"),
      meta: {
        noRefreshToken: true
      }
    },
    {
      path: "/ActiveEmail/:secretKey",
      name: "ActiveEmail",
      props: true,
      component: () => import("../src/view/Login/ActiveEmail.vue"),
      meta: {
        noRefreshToken: true
      }
    }
  ]
}
