import { getProfile } from "@/api/common"
import * as storeModule from "../index"
import { useCommonStore, useAppStore } from "../index"
import { strCapitalize } from "../util.js"
import { getAllProfiles } from "@/api/Advertising/profile"
import { PacvueGetPathInfo } from "@/utils/common-util"
export default {
  TEST_FN(playload) {
    var commonStore = useCommonStore()
    // eslint-disable-next-line import/namespace
    storeModule[`use${strCapitalize(commonStore.platform)}Store`]().TEST_FN(playload)
    //commit(`${state.platform}/TEST_FN`, playload, { root: true })
  },
  getProfileList(playload, isRefresh) {
    var appStore = useAppStore()
    var curStore = this.curStore
    return new Promise((resolve) => {
      var cacheData = curStore.profileList || []
      if (cacheData.length && !isRefresh) {
        resolve(cacheData)
        return
      }
      var profileList = []
      try {
        profileList = JSON.parse(sessionStorage.getItem("profileList"))
      } catch (ex) {
        profileList = []
      }
      if (profileList && profileList.length) {
        var data = profileList
        curStore.setProfileList(data)
        this.customAutoSetSelectedProfile(data)
        resolve(data)
      } else {
        if (!playload || playload.length == 0) {
          playload = appStore.chooseAccounts
        }
        getProfile(playload).then((data) => {
          curStore.setProfileList(data)
          sessionStorage.setItem("profileList", JSON.stringify(data))
          this.customAutoSetSelectedProfile(data)
          resolve(data)
        })
      }
    })
  },
  setProfileList(data) {
    this.curStore.setProfileList(data)
    sessionStorage.setItem("profileList", JSON.stringify(data))
  },
  customAutoSetSelectedProfile(profileList = []) {
    //是否自动设置选中Profile
    var { firstMenuLower } = PacvueGetPathInfo()
    var curStore = this.curStore
    if (curStore.selectedProfiles.length == 0 && profileList.length >= 30) {
      var appStore = useAppStore()
      var selectedCamTags = curStore.selectedCamTags || []
      var selectedCampaigns = curStore.selectedCampaigns || []
      var isAdAutoSet = !selectedCamTags.length && !selectedCampaigns.length
      if (firstMenuLower == "home") {
        curStore.setSelectedProfiles([profileList[0].id])
      } else if (isAdAutoSet) {
        curStore.setSelectedProfiles([profileList[0].id])
        appStore?.CLEAR_CASDADERFILTER() //清空级联相关的filter
        appStore = null
      }
    }
  },
  getAllProfileList(playload, isRefresh) {
    var appStore = useAppStore()
    var curStore = this.curStore
    return new Promise((resolve) => {
      var cacheData = curStore.allProfileList || []
      if (cacheData.length && !isRefresh) {
        resolve(cacheData)
        return
      }

      if (!playload || playload.length == 0) {
        playload = appStore.chooseAccounts
      }
      getAllProfiles(playload).then((data) => {
        curStore.setAllProfileList(data)
        resolve(data)
      })
    })
  },
  getNoneProfileList(playload, isRefresh) {
    var appStore = useAppStore()
    return new Promise((resolve) => {
      var profileList = []
      try {
        profileList = JSON.parse(sessionStorage.getItem("profileList"))
      } catch (ex) {
        profileList = []
      }
      if (profileList && profileList.length) {
        var data = profileList
        resolve(data)
      } else {
        if (!playload || playload.length == 0) {
          playload = appStore.chooseAccounts
        }
        getProfile(playload).then((data) => {
          sessionStorage.setItem("profileList", JSON.stringify(data))
          resolve(data)
        })
      }
    })
  },
  SET_PLATFORM(value) {
    this.platform = value || "amazon"
    localStorage.setItem("productline", value)
  },
  // 设置排序面板状态
  SET_SORT_PANEL_STATUS(value) {
    this.sortPanelStatus = value
  },
  // 设置排序面板数据
  SET_DASHBOARD_ARRAY(value) {
    this.dashboardArray = value
  },
  // 设置其他排序面板数据
  SET_OTHER_DASHBOARD_ARRAY(value) {
    this.otherDashboardArray = value
  },
  // 设置profileIds以及selectList
  SET_PROFILE_IDS(value) {
    //this.profileIds = value
    this.setSelectedProfiles(value)
  },
  // 设置analyticsProfileId
  SET_ANA_PROFILE_ID(value) {
    this.analyticsProfileId = value
  },
  // 设置analyticsAdvertiser
  SET_ANA_ADVER(value) {
    this.advertiserIds = value
  },
  // 设置analyticsAttribution
  SET_ANA_ATTR(value) {
    this.attrAdvertiserIds = value
  },
  // 设置profile-selectList
  SET_PROFILE_LIST(value) {
    this.profileList = value
  },
  // 设置CampaignTags
  SET_CAMPAIGN_TAGS(value) {
    this.campaignTags = value //home页面不需要同步
    //this.setSelectedCampaignTags(value)
  },
  // 设置CampaignTags（带父级）
  SET_CAMPAIGN_TAGS_WITH_PARENT(value) {
    this.campaignTagsWithParent = value
  },
  // 设置CampaignTagsAll
  SET_CAMPAIGN_TAGS_ALL(value) {
    this.campaignTagsAll = value
  },
  // 设置Porfolios
  SET_PORFOLIOS(value) {
    this.portfolio = value //home页面不需要同步
    //this.setSelectedPorfolios(value)
  },
  // 设置Share偏好
  SET_SHARE_PREFERENCE(value) {
    this.sharePreference = value
  },
  //设置per 三个指标
  SET_CONTENT_PER(value) {
    this.showContentPer = value
  },
  //设置Eff 三个指标
  SET_CONTENT_EFF(value) {
    this.showContentEff = value
  },
  //设置homeShare的SOV以及keyword配置
  SET_HOME_SHARE_CONFIG(value) {
    this.homeShareSettingConfig = value
  },
  setSelectedProfiles(playload) {
    this.curStore.setSelectedProfiles(playload)
  },
  setSelectedCampaignTags(playload) {
    this.curStore.setSelectedCamTags(playload)
  },
  setSelectedPorfolios(playload) {
    this.curStore.setSelectedPortfolios(playload)
  },
  setSelectedCampaigns(playload) {
    this.curStore.setSelectedCampaigns(playload)
  },
  setSelectedAdgroups(playload) {
    this.curStore.setSelectedAdgroups(playload)
  },
  setUserPersist() {
    this.curStore.setUserPersist()
  },
  logout() {
    console.log(storeModule, "1")
    for (var i in storeModule) {
      // eslint-disable-next-line import/namespace
      storeModule[i]().$reset()
    }
  },
  //home widget 放大功能
  SET_HOME_PRODUCTS(value) {
    this.homeProducts = value
  },
  SET_HOME_BUDGET(value) {
    this.homeBudget = value
  },
  SET_SOV_BUDGET(value) {
    this.sovBudget = value
  },
  SET_HOME_PRESCRIPTIVEINSIGHTBYTAG(value) {
    this.homePrescriptiveInsightByTag = value
  },
  SET_HOME_BRANDSOV(value) {
    this.homeBrandSOV = value
  },
  SET_HOME_BRANDSOV_TRACKVALUE(value) {
    this.homeBrandSOVTrackValue = value
  },
  SET_HOME_CATEGORYVALUEANDSORT(value) {
    this.homeCategoryValueAndSort = value
  },
  SET_HOME_CAMPAIGNTAGPER(value) {
    this.homeCampaignTagPer = value
  },
  SET_HOME_PLACEMENTPERF(value) {
    this.homePlacementPerf = value
  },
  SET_HOME_TOP20(value) {
    this.homeTop20 = value
  },
  SET_HOME_CAMPAIGNGOAL(value) {
    this.homeCampaignGoal = value
  },
  SET_HOME_NONBID(value) {
    this.homeNonBid = value
  },
  // SET_HOME_KEYWORDS(value) {
  //   this.homeKeywords = value
  // },
  SET_HOME_KEYWORDSCOL(value) {
    this.homeKeywordsColumn = value
  },
  SET_HOME_KEYWORDSMATCHTYPE(value) {
    this.homeKeywordsMatchType = value
  },
  SET_HOME_KEYWORDSTAGPERF(value) {
    this.homeKeywordsTagPerf = value
  },
  SET_HOME_TOPWIDGETSDATA(value) {
    this.homeTopWidgetsData = value
  },
  SET_SETTINGSTATE(value) {
    this.SettingState = value
  },
  SET_HOME_TOPPRODUCT(value) {
    this.homeTopCampaign = value
  },
  setAdvertisingExplorerShowMode(playload) {
    this.curStore.setAdvertisingExplorerShowMode(playload)
  },
  setCamTagPermissionOn(playload) {
    this.camTagPermissionOn = playload
  }
}
