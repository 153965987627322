export default function () {
  return {
    platform: localStorage.getItem("productline") || "amazon", // amazon,walmart,criteo,instacart
    sortPanelStatus: false, //控制下拉排序面板状态
    dashboardArray: [], //面板数据状态
    otherDashboardArray: [], //其他面板数据状态
    profileList: [], //list
    //profileIds: [], //id集合-需要同步
    homeProfileIds: [], //id集合-需要同步
    campaignTagsAll: [], //所有的campaignTags(包含子级)
    campaignTagsWithParent: [], //包含父级tag的集合
    campaignTags: [], //tags集合-需要同步
    portfolio: [], //portfolio集合-需要同步
    analyticsProfileId: [],
    advertiserIds: [],
    attrAdvertiserIds: [],
    sharePreference: "ACOS",
    showContentPer: [],
    showContentEff: [],
    homeProducts: 2, //home products表默认选择2
    homeBudget: 2, // budget表默认选择2
    sovBudget: 2,
    homePrescriptiveInsightByTag: 1,
    homeBrandSOV: 1,
    homeBrandSOVTrackValue: [],
    homeCategoryValueAndSort: ["", "ShareOfShelf"],
    homeCampaignTagPer: 1,
    homePlacementPerf: "SP",
    homeTop20: "Sales",
    homeCampaignGoal: 1,
    homeNonBid: 1,
    // homeKeywords: 1,
    homeKeywordsColumn: ["0", "Spend"],
    homeKeywordsMatchType: [],
    homeKeywordsTagPerf: [],
    homeShareSettingConfig: {},
    homeTopWidgetsData: null,
    SettingState: 0,
    homeTopCampaign: 1,
    camTagPermissionOn: undefined,
    homeIsAllWidgetsLoaded: false //home页面所有widgets是否加载完成
  }
}
