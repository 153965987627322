import request from "../request"
const VITE_APP_GPT = import.meta.env.VITE_APP_GPT
const VITE_APP_MENU_API = import.meta.env.VITE_APP_MENU_API
const VITE_APP_APIENV = import.meta.env.VITE_APP_APIENV

/**
 * @deprecated Copilot 1.0 GPT 接口
 */
export function queryfilter(data) {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/queryfilter`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function feedbackApi(data) {
  return request({
    url: `${VITE_APP_MENU_API}user/feedback`,
    method: "post",
    fullData: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function downloadApi(data) {
  return request({
    url: `${VITE_APP_GPT}api/Download/DownloadData`,
    method: "post",
    responseType: "blob",
    fullData: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function CommentQuestionQuery(data) {
  return request({
    url: `${VITE_APP_GPT}api/FeadBack/CommentQuestionQuery`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function appkey() {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/appkey`,
    method: "get",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function verifykey(data) {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/verifykey`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

// confirm insight
export function confirmInsight() {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/enableInsight`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function getSessionId() {
  return request({
    url: `${VITE_APP_GPT}api/id_generator/GetSessionId`,
    method: "get",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function queryKnowledgeBase(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/kb`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function queryData(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/querydata`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function insightApi(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/insight`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function insightTag(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/insightTag`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function saveFreshmanGuidanceAction(data) {
  return request({
    url: `${VITE_APP_GPT}api/FeadBack/FirstCopilotFeedback`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}
