import Main from "@pacvue/frame/layout/Main.vue"

export default {
  router: {
    path: "/Report/CustomDashboard",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Report/CustomDashboard",
        component: () => import("@customDashboard/index.vue"),
        name: $t("Custom Dashboard")
      },
      {
        path: "/Report/CustomDashboard/Create",
        component: () => import("@customDashboard/Dashboard/CreateDashboard.vue"),
        name: "Create Dashboard",
        meta: {
          noSideBar: false,
          i18N: $t("Create Dashboard")
        }
      },
      {
        path: "/Report/CustomDashboard/Edit",
        component: () => import("@customDashboard/Dashboard/CreateDashboard.vue")
      },
      {
        path: "/Report/CustomDashboard/ViewSample",
        component: () => import("@customDashboard/Dashboard/CreateDashboard.vue"),
        meta: {
          i18N: $t("View Sample")
        }
      },
      {
        path: "/Report/CustomDashboard/Detail",
        component: () => import("@customDashboard/Detail/DashboardDetail.vue"),
        meta: {
          parent: "Custom Dashboard",
          isShowTime: true
        }
      },
      {
        path: "/Report/CustomDashboard/TemplateManagement",
        component: () => import("@customDashboard/TemplateManagements/index.vue"),
        meta: {
          parent: "Custom Dashboard",
          isShowTime: false
        }
      },
      {
        path: "/Report/CustomDashboard/TemplateManagement/BulkApplyTemplate",
        component: () => import("@customDashboard/TemplateManagements/bulkCreate.vue"),
        meta: {
          parent: "Custom Dashboard",
          isShowTime: false
        }
      },
      {
        path: "/Share/CustomDashboard",
        component: () => import("@customDashboard/Share/sharePage/index.vue"),
        meta: {
          parent: "Custom Dashboard",
          isShowTime: true
        }
      },
      {
        path: "/Share/ShareEmpty",
        component: () => import("@customDashboard/Share/ShareEmpty.vue")
      }
    ]
  }
}
