import { PAGE_QUESTIONS_PRESETS } from "../src/Copilot/config"

const judgeActiveCopilotGuidingPopByRoute = (routePath) => {
  for (const entry of PAGE_QUESTIONS_PRESETS) {
    if (entry[0].test(routePath)) {
      return true
    }
  }
  return false
}

const getCopilotQuestionsPresetsByRoute = (routePath) => {
  for (const entry of PAGE_QUESTIONS_PRESETS) {
    if (entry[0].test(routePath)) {
      return entry[1]
    }
  }
  return []
}

export { judgeActiveCopilotGuidingPopByRoute, getCopilotQuestionsPresetsByRoute }
