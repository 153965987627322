import { useUserStore, useAppStore, useCommonStore } from "@/store"

export default {
  userInfoInterceptors: [
    // return thenable object
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const userStore = useUserStore()
          const appStore = useAppStore()
          const commonStore = useCommonStore()
          await userStore.userAction({ to, from })
          appStore.setUserPersist()
          commonStore.setUserPersist()
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ],
  settingInterceptors: []
}
