import axios from "axios"

// console.log(import.meta.env)

const { VITE_APP_USERURL } = import.meta.env
// var userURL = process.env.VUE_APP_USERURL
const _axios = axios.create({
  baseURL: VITE_APP_USERURL,
  timeout: 30000
})
_axios.interceptors.request.use(async function (config) {
  config.headers["productline"] = config.headers?.productline || localStorage.getItem("productline")
  return config
})
export default _axios
