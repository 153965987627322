<template>
    <el-popover
      :width="220"
      trigger="hover"
      :visible="popperVisible"
      placement="bottom-start"
      :popper-options="{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-16, 8]
            }
          }
        ]
      }"
    >
      <template #reference>
        <div class="relative cursor-pointer pacvue-downloadCenter-wrap" style="width: 22px; height: 22px" id="pacvue-downloadCenter-wrap">
          <el-progress type="circle" :percentage="processVal" v-if="popperVisible&&showProgressbar" :show-text="false" :width="20" :stroke-width="2" :indeterminate="true" />
          <!-- <el-icon :size="20" v-if="popperVisible" class="pacvue-loading_loading_icon"><Loading /></el-icon> -->
          <PacvueTooltip v-else placement="top" trigger="hover" effect="dark" :content="$t('key2782')">
            <el-icon class="cursor-pointer" style="font-size: 22px" @click="handleClick"><PacvueIconDownload /></el-icon>
          </PacvueTooltip>
        </div>
      </template>
      <template #default>
        <div style="word-wrap: break-word; word-break: break-word">{{ $t("You have {0} download task in progress.", [downloadingCount]) }}</div>
      </template>
    </el-popover>
  </template>
  <script setup>
  import { ref, defineProps, defineEmits, watch, computed } from "vue"
  var props = defineProps({
    downloadingCount: {
      //正在下载中的数目
      type: Number,
      default: 0
    },
    timeout: {
      //定时器时间，默认5秒
      type: Number,
      default: 5000
    },
    showProgressbar:{
      //是否显示进度条
      type:Boolean,
      default:false
    }
  })
  var emits = defineEmits(["click"])
  var popperVisible = ref(false)
  const debounce = (fn, delay) => {
    let timer = null
    const _debounce = function (...args) {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        fn.apply(this, args)
        timer = null
      }, delay)
    }
    return _debounce
  }
  var processVal = ref(0)
  var processTimeHandler = null
  var startProcess = () => {
    processTimeHandler && clearInterval(processTimeHandler)
    processVal.value = 0
    processTimeHandler = setInterval(() => {
      processVal.value = processVal.value + 5
    }, 5000 / 20)
  }
  var handlerStart = computed(() => {
    return debounce(() => {
      popperVisible.value = false
    }, props.timeout)
  })
  watch(
    () => props.downloadingCount,
    (newVal, oldVal) => {
      if (newVal) {
        popperVisible.value = true
        startProcess()
        handlerStart.value()
      } else {
        popperVisible.value = false
      }
    },
    {
      immediate: true
    }
  )
  
  var handleClick = () => {
    emits("click")
  }
  </script>
  