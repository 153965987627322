<template>
  <div class="headquarter-select-panel flex py-2 pr-4">
    <div v-for="([group, items], index) in Object.entries(allMenus)" :key="group" class="relative flex flex-col pl-4">
      <!-- Level1 Group -->
      <div class="group-title flex items-center h-[44px]">
        <div class="flex items-center justify-center w-6 h-6 rounded-[50%] bg-[var(--hover-color--)]">
          <el-icon :size="20" color="var(--pac-theme-color)"><component :is="groupIconMap[group]" /></el-icon>
        </div>
        <span class="ml-1 text-[16px] leading-5 text-[#45464f] font-semibold">{{ groupLocaleMap[group] }}</span>
      </div>
      <div v-if="!Array.isArray(items)" class="group-content flex">
        <div v-for="([subGroup, subItems], l2Index) in Object.entries(items)" :key="subGroup" class="flex flex-col">
          <!-- Level2 Group -->
          <div class="sub-group-title flex items-center h-8 pl-7 pr-3 text-[#b2b2b8] text-[12px] leading-[18px] font-medium" :class="{ '!pl-3': l2Index > 0 }">{{ groupLocaleMap[subGroup] }}</div>
          <div
            v-for="{ menuName, url, i18N, betaTag, alphaTag, newTag } in subItems"
            :key="menuName"
            class="group-items flex items-center h-10 pl-7 pr-3 bg-[#fff] duration-300 transition-all rounded-[4px] leading-5 cursor-pointer hover:bg-[var(--hover-color--)] hover:text-[var(--pac-theme-color)]"
            :class="{ '!pl-3': l2Index > 0 }"
            @click="handleChannelItemClick(url)"
          >
            <!-- Level3 Menu -->
            <span>{{ i18N }}</span>
            <div v-if="betaTag || alphaTag || newTag" class="ml-1 px-1.5 bg-[var(--pac-theme-color)] text-[10px] leading-[18px] rounded-2xl font-medium text-white">
              {{ menuTagMapper({ betaTag, alphaTag, newTag }) }}
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <div
          v-for="{ menuName, url, i18N, betaTag, alphaTag, newTag } in items"
          :key="menuName"
          class="group-items flex items-center h-10 px-7 bg-[#fff] duration-300 transition-all rounded-[4px] leading-5 cursor-pointer hover:bg-[var(--hover-color--)] hover:text-[var(--pac-theme-color)]"
          @click="handleChannelItemClick(url)"
        >
          <!-- Level2 Menu -->
          <span>{{ i18N }}</span>
          <div v-if="betaTag || alphaTag || newTag" class="ml-1 px-1.5 bg-[var(--pac-theme-color)] text-[10px] leading-[18px] rounded-2xl font-medium text-white">
            {{ menuTagMapper({ betaTag, alphaTag, newTag }) }}
          </div>
        </div>
      </template>
      <!-- 分组右侧分割线 -->
      <div v-if="Object.keys(allMenus).length > 1 && index < Object.keys(allMenus).length - 1" class="group-separator absolute -right-2 top-0 bottom-0 w-[1px] bg-[#f2f3f6]"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { useAnalyticsTrack } from "../../analytics/index"
import { jumpToRetailDashboard } from "../../common"

const props = defineProps({
  headquarterMenu: {
    type: Object,
    default: () => ({})
  }
})

const groupIconMap = {
  "Executive Hub": "PacvueIconDspReport",
  "Cross Retailer": "PacvueIconDocument",
  "Detailed Analytics": "PacvueIconCalendarSetting"
}

const groupLocaleMap = {
  "Cross Retailer": $t("Cross Retailer"),
  "Detailed Analytics": $t("Detailed Analytics"),
  REPORTS: $t("Reports"),
  TOOLS: $t("Tools")
}

const allMenus = ref([])

// effect
watch(
  () => props.headquarterMenu,
  (menu) => {
    if (menu?.length > 0) {
      const _allMenus = []
      for (const menuItem of menu) {
        if (menuItem.menuName.startsWith("Cross Retailer")) {
          let keyName = ""
          if (menuItem.menuName.toLowerCase().includes("tool")) {
            keyName = "TOOLS"
          } else if (menuItem.menuName.toLowerCase().includes("report")) {
            keyName = "REPORTS"
          }
          for (const subItem of menuItem.children) {
            if (subItem.permissionType > 0) {
              if (!_allMenus["Cross Retailer"]) {
                _allMenus["Cross Retailer"] = { [keyName]: [] }
              }
              if (!_allMenus["Cross Retailer"][keyName]) {
                _allMenus["Cross Retailer"][keyName] = []
              }
              _allMenus["Cross Retailer"][keyName].push(subItem)
            }
          }
        } else if (menuItem.children?.length > 0) {
          for (const subItem of menuItem.children) {
            if (subItem.permissionType > 0) {
              if (!_allMenus[menuItem.menuName]) {
                _allMenus[menuItem.menuName] = []
              }
              _allMenus[menuItem.menuName].push(subItem)
            }
          }
        }
      }
      allMenus.value = _allMenus
    }
  },
  {
    immediate: true
  }
)

const menuTagMapper = ({ alphaTag, betaTag, newTag }) => {
  if (alphaTag) {
    return $t("Alpha")
  } else if (betaTag) {
    return $t("Beta")
  } else if (newTag) {
    return $t("New")
  }
}

// 事件处理
const handleChannelItemClick = (path) => {
  useAnalyticsTrack("Click Cross Retailer Dashboard", { category: "Experience" }, () => {
    jumpToRetailDashboard("retailer", { isDomainUnified: true, redirect: path })
  })
}
</script>

<style scoped lang="scss"></style>
