import { commonJS } from "@pacvue/utils"
import { useAppStore } from "@/store"
export function useRouterInterceptor() {
  return {
    beforeEnter(to, from, next) {
      let routerQuery = commonJS.PacvueGetPageQuery({
        query: to?.query?.query,
        isCompression: true
      })
      let explorerPickerData = routerQuery?.explorerPickerData
      let ExplorerInfo = routerQuery?.ExplorerInfo
      if (!ExplorerInfo) {
        next("/home")
        return
      }
      let enableCompareCheck = true
      const appStore = useAppStore()
      let oldMeta = to.meta || {}
      if (explorerPickerData) {
        let { date: dateRange, compareDate: dateCompareRange, isCompare, compareSummary, isCompareCustom, showCompareCheck } = explorerPickerData
        enableCompareCheck = showCompareCheck
        appStore.SET_DATERANGE(dateRange)
        appStore.SET_COMPARETIME(dateCompareRange)
        appStore.SET_ISCOMPARE(isCompare)
        appStore.SET_ISCOMPARECUSTOM(isCompareCustom)
        appStore.SET_COMPARESUMMARY(compareSummary)
      } else {
        let hasCompare = true
        let explorLevel = ExplorerInfo?.Level
        let TabSelect = ExplorerInfo?.TabSelect
        if (["asin", "keyword"].includes(explorLevel)) {
          hasCompare = ["ASINPerformance", "KeywordPerformance"].includes(TabSelect)
        }
        enableCompareCheck = hasCompare
        appStore.SET_SHOWDATECAMPRE(hasCompare)
      }
      to.meta = {
        ...oldMeta,
        showCompareCheck: enableCompareCheck
      }
      next()
    }
  }
}
