import { useCommonStore } from "@/store"
import { IsAuthorizedByAmazon, getSettings } from "@/api/account"
import { dayjs } from "@pacvue/element-plus"
import { PacvueGetLanguage } from "@/utils/common-util"

const elementLocale = PacvueGetLanguage()
dayjs.locale(elementLocale)

dayjs.updateLocale(elementLocale, {
  weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
  yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
})

export default {
  routerInterceptors: async (to, from, next) => {
    const commonStore = useCommonStore()
    //需要兼容profileId为-10的情况
    const pathName = to.path?.toLowerCase()
    const selectedProfiles = commonStore.selectedProfiles ?? []
    if (pathName != "/campaign/campaigntagging") {
      if (selectedProfiles.length == 1 && selectedProfiles[0] == -10) {
        commonStore.setSelectedProfiles([])
      }
    }
    if (sessionStorage.getItem("AuthData") && sessionStorage.getItem("AuthData") !== "undefined") {
      let aut = JSON.parse(sessionStorage.getItem("AuthData"))
      commonStore.SET_SETTINGSTATE(aut.SettingState)
    } else {
      if (!to.query.shareId) {
        const AuthData = await IsAuthorizedByAmazon()
        localStorage.removeItem(AuthData.UserId + "_BindProfile") // 授权了有profile
        localStorage.removeItem(AuthData.UserId + "_NoneAuthorized") // 没授权
        localStorage.removeItem(AuthData.UserId + "_SettingState") // 设置setting状态
        if (AuthData.IsAuthorized) {
          localStorage.setItem(AuthData.UserId + "_NoneAuthorized", false)
          if (!AuthData.SettingState) {
            // 授权了 未设置完成
            if (AuthData.IsBindProfile) {
              localStorage.setItem(AuthData.UserId + "_BindProfile", true)
            } else {
              localStorage.setItem(AuthData.UserId + "_BindProfile", false)
            }
            localStorage.removeItem(AuthData.UserId + "_esaySetting")
            localStorage.setItem(AuthData.UserId + "_SettingState", AuthData.SettingState)
            commonStore.SET_SETTINGSTATE(AuthData.SettingState)
          } else {
            // 授权完成正常进入
            localStorage.setItem(AuthData.UserId + "_esaySetting", true)
            localStorage.setItem(AuthData.UserId + "_SettingState", AuthData.SettingState)
            commonStore.SET_SETTINGSTATE(AuthData.SettingState)
          }
        } else {
          // 未授权
          localStorage.setItem(AuthData.UserId + "_NoneAuthorized", true)
          localStorage.setItem(AuthData.UserId + "_BindProfile", false)
          localStorage.removeItem(AuthData.UserId + "_esaySetting")
        }
        sessionStorage.setItem("AuthData", JSON.stringify(AuthData))
        commonStore.SET_SETTINGSTATE(AuthData.SettingState)
      }
    }
  },
  settingInterceptors: [
    (context, to, from) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const devZH = localStorage.getItem("dev6ZHSet")
          let i8 = devZH ? devZH : "EN"
          let settingConfig = null
          if (sessionStorage.getItem("setting")) {
            const setting = JSON.parse(sessionStorage.getItem("setting"))
            // analyticsTrackUserSetting(setting)
            i8 = setting.language
            settingConfig = setting
            if (setting.themeColour == "white") {
              document.getElementsByTagName("body")[0].setAttribute("class", "theme1")
            } else if (setting.themeColour == "black") {
              document.getElementsByTagName("body")[0].setAttribute("class", "theme2 borderversion")
            } else {
              document.getElementsByTagName("body")[0].setAttribute("class", "theme2")
            }
          } else {
            const setting = await getSettings(context.user.userId)
            // analyticsTrackUserSetting(setting)
            settingConfig = setting
            if (setting.themeColour == "white") {
              document.getElementsByTagName("body")[0].setAttribute("class", "theme1")
            } else if (setting.themeColour == "black") {
              document.getElementsByTagName("body")[0].setAttribute("class", "theme2 borderversion")
            } else {
              document.getElementsByTagName("body")[0].setAttribute("class", "theme2")
            }
            i8 = setting.language
          }
          // if (i18n.mode === "legacy") {
          //   i18n.global.locale = i8
          // } else {
          //   i18n.global.locale.value = i8
          // }
          if (i8 !== localStorage.getItem("locale")) {
            window.prefLangDiffered = true
            localStorage.setItem("locale", i8)
          }

          let elementLocale = "en"
          if (localStorage.getItem("locale") === "ZH") {
            elementLocale = "zh-cn"
          } else if (localStorage.getItem("locale") === "JA") {
            elementLocale = "ja"
          }
          dayjs.updateLocale(elementLocale, {
            weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
            yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
          })
          if (window.__inject_pacvue_element_changeLocale__) {
            window.__inject_pacvue_element_changeLocale__({
              option: { locale: { name: elementLocale } }
            })
          }
          if (settingConfig) {
            context.SET_USERSETTINGS(settingConfig)
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ]
}
