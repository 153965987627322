import { app } from "../frame.js"
import { reactive, ref, computed } from "vue"

export function useCustomDownload({ appStore }) {
  var downloadFileMap = reactive({})
  var handlerDataDownLoad = (downloadInfo) => {
    var uuid = downloadInfo.uuid
    var fileName = getFileBaseName(downloadInfo.fileName ?? "")
    var detail = downloadInfo.detail //"Data Download Start"
    var isCompleted = ["Data Download Succeed", $t("Data Download Succeed")].includes(detail)
    if (fileName) {
      if (!downloadFileMap[fileName]) {
        if (!isCompleted) {
          downloadFileMap[fileName] = {
            ...downloadInfo,
            isCompleted
          }
        }
      } else if (isCompleted) {
        delete downloadFileMap[fileName]
      }
    }
  }
  app.config.globalProperties.$eventHub.on("customDataDownload", (msgInfo) => {
    if (msgInfo) {
      handlerDataDownLoad(msgInfo)
    }
  })
  var downloadingFileList = computed(() => {
    return Object.values(downloadFileMap).filter((item) => !item.isCompleted)
  })
  var downloadingCount = computed(() => {
    return downloadingFileList.value.length
  })
  return {
    downloadingFileList,
    handlerDataDownLoad,
    downloadingCount
  }
}
function getFileBaseName(fileName = "") {
  var fileNameArr = fileName.split(".")
  var fileNameArrLen = fileNameArr.length
  var fileNameStr = ""
  if (fileNameArr.length == 1) {
    return fileName
  }
  for (var i = 0; i < fileNameArrLen - 1; i++) {
    fileNameStr += fileNameArr[i] + (i == fileNameArrLen - 2 ? "" : ".")
  }
  return fileNameStr
}
