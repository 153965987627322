import { pacvueToken } from "@pacvue/utils"
import authReq from "../src/authRequest"

const { VITE_APP_USERURL } = import.meta.env

const handleAuthCodeAndReloadPage = async (from, to) => {
  sessionStorage.clear()
  if (to.query.authcode) {
    const productLine = to.query.query
    const searchParams = new URLSearchParams(window.location.search)
    let targetPath = to.redirectedFrom.href.split("?")[0]
    const api = searchParams.get("authType") === "SSO" ? "bySSOAuthCode" : "byCrossAuthCodev2"
    const res = await authReq({
      url: `${VITE_APP_USERURL}login/${api}?authCode=${to.query.authcode}`,
      method: "get",
      headers: {
        productline: productLine
      }
    })
    // 此处有 SSO => Multi Client 的可能
    if (res.data.code == 212) {
      // 去往 SSO 登录页的 Multi Client 选择
      targetPath = `${window.location.pathname}/login/loginwithsso`.replace("/SSL/INDEX", "/").replace("//", "/")
      searchParams.append("type", "MC")
      searchParams.delete("authType")
      // 结果存 local, 以便给 SSO 登录页使用
      localStorage.setItem("sso_mc_info", JSON.stringify(res))
    } else {
      pacvueToken.setPacvue_token(res)
    }
    searchParams.delete("authcode")
    location.href = `${targetPath}?${searchParams.toString()}`
  }
}

let url = ""
const recursiveFindMenuPathByAlias = (menus, aliases) => {
  for (const subMenu of menus) {
    if (aliases.includes(subMenu.alias)) {
      url = subMenu.url
    } else if (subMenu.children?.length > 0) {
      recursiveFindMenuPathByAlias(subMenu.children, aliases)
    }
  }
  return url
}

export { handleAuthCodeAndReloadPage, recursiveFindMenuPathByAlias }
