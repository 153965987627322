// 该路由配置用作新UI架构的可插拔, 老UI架构的微前端嵌入路由配置位于入口文件 pages/[module-name].js

import Main from "@pacvue/frame/layout/Main.vue"
import useUserStore from "@pacvue/frame/user"
import request from "@pacvue/frame/request"
import commonJS from "@pacvue/utils"
import { PacvueMessage } from "@pacvue/element-plus"

const { VITE_APP_MENU_API } = import.meta.env

export default {
  routerInterceptors: async (to, from, next) => {
    if (to.path.includes("/Permission/UserManage") || to.path.includes("/Permission/RoleManage")) {
      const loginUserInfo = useUserStore().user
      const routeQuery = to.query.q ? JSON.parse(commonJS.decrypt(to.query.q)) : {}
      if (routeQuery.type === "edit") {
        // 此处不能直接复用 ./api 中的方法, 因为路由守卫进入时其依赖的 commonStore 还未创建
        const editUserInfo = await request({
          url: `${VITE_APP_MENU_API}userManager/getUserInfo/${routeQuery.userId}`,
          method: "get",
          isIgnoreRequestRegister: true
        })
        if (editUserInfo.clientId != loginUserInfo.clientId) {
          PacvueMessage({
            message: "The user being edited does not exist in your client!",
            type: "error"
          })
          next("/Permission/NoPermission")
        }
      }
      if (loginUserInfo.level === "User" || !loginUserInfo.level) {
        next("/Permission/NoPermission")
      }
    }
  },
  router: {
    path: "/Permission",
    component: Main,
    meta: { auth: true, id: -1 },
    redirect: "/Permission/UserManage",
    children: [
      {
        path: "/Account/UserManage",
        redirect: "/Permission/UserManage"
      },
      {
        path: "/Permission/UserManage",
        component: () => import("@permission/views/pages/UserManagement.vue"),
        name: "User Management"
      },
      {
        path: "/Permission/RoleManage",
        component: () => import("@permission/views/pages/RoleManagement.vue"),
        name: "Role Management"
      },
      {
        path: "/Permission/UserManage/UserSetting",
        component: () => import("@permission/views/pages/UserSetting.vue"),
        name: "User Setting"
      },
      {
        path: "/Permission/NoPermission",
        component: () => import("@permission/views/pages/NoPermission.vue"),
        name: "NoPermission"
      }
    ]
  }
}
