import { $t } from "../i18nEntry"

export const indexDBname = () =>
  sessionStorage.getItem("useInfo") && sessionStorage.getItem("useInfo") != "undefined" ? `${JSON.parse(sessionStorage.getItem("useInfo")).userId}_Copilot_Conversation` : null

export const dataFormat = {
  date: "String",
  campaignname: "String",
  weekdayshow: "String",
  impression: "Dateformat",
  click: "Dateformat",
  spend: "Dateformat",
  sales: "Dateformat",
  ctr: "Dateformat",
  acos: "Dateformat",
  roas: "Dateformat",
  cpa: "Dateformat",
  cvr: "Dateformat",
  cpc: "Dateformat",
  totaladsales: "Dateformat",
  paidsov: "Dateformat",
  organicsov: "Dateformat",
  sbproductsov: "Dateformat",
  sbvsov: "Dateformat",
  shareofshelf: "Dateformat",
  sbsov: "Dateformat",
  spsov: "Dateformat",
  orders: "Dateformat",
  saleunits: "Dateformat"
}

export const nameArr = [
  "KeywordText",
  "ASIN",
  "Asin",
  "query",
  "targetText",
  "AdgroupName",
  "CampaignName",
  "CampaignType",
  "CampaignTagName",
  "AdGroupTagName",
  "AsinTagName",
  "KeywordTagName",
  "PortfolioName",
  "Brand",
  "VendorGroupName",
  "ProfileName",
  "SOVGroup"
]

export const SpMap = {
  tacos: "acos",
  troas: "roas",
  "t-acos": "acos",
  "t-roas": "roas",
  organicrevenue: "sales",
  totalrevenue: "sales",
  revenue: "sales",
  totaladsales: "sales",
  totalmediasales: "sales",
  totalcvr: "cvr"
}

export const insightPage = {
  Campaign: "Campaign",
  CampaignAdGroup: "Adgroup",
  Keyword: "Keyword",
  PAT: "PAT",
  CampaignAsin: "ASIN",
  CampaignProfile: "Profile",
  CampaignTagging: "CampaignTag",
  KeywordTagging: "KeywordTag",
  AsinTag: "AsinTag",
  "Product Listing": "ProductCenter-Asin",
  "SOV Brand": "SOV"
}

export const colors = ["#9B88FA", "#FFB268", "#75BDFF", "#FF9B9E", "#8FE4F0", "#6585F3", "#C2F372", "#6ADFA7", "#299AFF", "#DE9DF4", "#28DAC6", "#F0E360", "#F5AAD7", "#97E7D3", "#FFDF6F", "#00CFE8"]

// Map<Array<RegExp>, Array<String>>
export const PAGE_QUESTIONS_PRESETS = new Map([
  [/^\/Budget\/BudgetDashboard.*/, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
  [/^\/Optimization\/Rule.*/, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
  [/^\/Optimization\/Dayparting.*/, [$t("amskey3272"), $t("amskey3273"), $t("amskey3274")]],
  [/^\/Optimization\/Schedule.*/, [$t("amskey3275"), $t("amskey3276"), $t("amskey3277")]],
  [/^\/Optimization\/AutomationAI.*/, [$t("amskey3278"), $t("amskey3279"), $t("amskey3280"), $t("amskey3281"), $t("amskey3282")]],
  [/^\/Optimization\/ASINAI.*/, [$t("amskey3283"), $t("amskey3284"), $t("amskey3285"), $t("amskey3286")]],
  [/^\/AuditCenter.*/, [$t("amskey3287"), $t("amskey3288"), $t("amskey3289")]],
  [/^\/Optimization\/LiveMomentum.*/, [$t("amskey3290"), $t("amskey3291"), $t("amskey3292")]],
  [/^\/Optimization\/BidExplorer.*/, [$t("amskey3293"), $t("amskey3294"), $t("amskey3295")]]
])
