import Main from "@pacvue/frame/layout/Main.vue"

export default {
  routerInterceptors: async () => {
    const signFlag = localStorage.getItem("SignFlag")
    if (signFlag == "true") {
      localStorage.setItem("signFlagBillingTag", "true")
    }
  },
  router: {
    //BillingTag
    path: "/Campaign/BillingTag",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Campaign/BillingTag",
        name: "BillingTag",
        component: () => import("@billingTag/index.vue"),
        meta: {
          isShowTime: true,
          showCompareCheck: true,
          alias: "BillingTag"
        }
      }
    ]
  }
}
