import request from "@pacvue/frame/request"
var { VITE_APP_EventURL, VITE_APP_Download, VITE_APP_AnomalyAlert, VITE_APP_RULEURL, VITE_APP_EventMetaUrl } = import.meta.env
const productline = localStorage.getItem("productline")
const VITE_APP_APIREPORT = productline === "amazon" ? import.meta.env.VITE_APP_APIREPORT : ""
// message
export function getMessages(data) {
  return request({
    url: `${VITE_APP_EventURL}notification/getMessages`,
    data,
    method: "post"
  })
}
export function showMessageType(data) {
  return request({
    url: `${VITE_APP_EventURL}notification/showMessageType`,
    data,
    method: "get"
  })
}
export function markOneAsRead(data = {}) {
  return request({
    url: `${VITE_APP_EventURL}notification/markOneAsRead`,
    method: "POST",
    params: data
  })
}
export function markAllAsRead(data = {}) {
  return request({
    url: `${VITE_APP_EventURL}notification/markAllAsRead`,
    method: "post",
    data
  })
}

// download
export function download(downloadUrl) {
  return request({
    url: `${downloadUrl}`,
    method: "get",
    responseType: "blob"
  })
}
export function listTask(data = {}) {
  return request({
    url: `${VITE_APP_Download}/listTask`,
    method: "post",
    data
  })
}
export function retryTask(data = {}) {
  return request({
    url: `${VITE_APP_Download}/retryTask`,
    method: "post",
    data
  })
}

// anomaly alert
export function getAlertInfo(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAlertInfo`,
    method: "post",
    data
  })
}
export function getProfilePermissionAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getProfilePermissionAlert`,
    method: "post",
    data
  })
}
export function getAsinStatusAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAsinStatusAlert`,
    method: "post",
    data
  })
}
export function getAsinOutOfStockAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAsinOutOfStockAlert`,
    method: "post",
    data
  })
}
export function campaignStatusAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/campaignStatusAlert`,
    method: "post",
    data
  })
}
export function getAdAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAdAlert`,
    method: "post",
    data
  })
}
export function getRuleAlertDetail(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/history/getRuleAlertDetail`,
    method: "post",
    data
  })
}
export function getIdsByAsin(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getIdsByAsin`,
    method: "post",
    data
  })
}
export function getCampaignStatusProfile(data = {}) {
  return request({
    url: `${VITE_APP_EventMetaUrl}/filter/profiles`,
    method: "post",
    data
  })
}
export function setIgnore(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/skip`,
    method: "post",
    data
  })
}
export function exportAlertInfo(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/export/exportAlertInfo`,
    method: "post",
    responseType: "blob",
    data
  })
}
// manage alert
export function getAlertSetting(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAlertSetting`,
    method: "post",
    data
  })
}
export function changeManageAlertStatus(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/status`,
    method: "post",
    data
  })
}
export function ManageAlertSetting(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/setting`,
    method: "post",
    data
  })
}
// ignore list
export function getSkipList(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/skipList`,
    method: "post",
    data
  })
}
export function ReEnable(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/reEnable`,
    method: "post",
    data
  })
}
// amazon
export function getProductEligibilityCheck(params) {
  return request({
    url: `${VITE_APP_APIREPORT}/api/DefaultReport/v3/GetProductEligibilityCheck`,
    method: "get",
    params,
    cancelToken: true
  })
}
