import request from "@pacvue/frame/request"
const { VITE_APP_MENU_API, VITE_APP_DOWNLOAD, VITE_APP_BASEURL, VITE_APP_ADMIN_MIDDLE, VITE_APP_USERURL, VITE_APP_META } = import.meta.env
export function IsAuthorizedByAmazon() {
  return request({
    url: `${VITE_APP_BASEURL}Setting/V3/IsAuthorizedByAmazon`,
    method: "GET"
  })
}
export function GetProfileAmazonList(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/GetProfileAmazonList`,
    method: "post",
    data: data
  })
}
export function SetManagedForAmazonAccount(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/SetManagedForAmazonAccount`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function SetManagedForAmazonAccounts(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/SetManagedForAmazonAccounts`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function GetAllUserGroups(data) {
  return request({
    url: `${VITE_APP_BASEURL}UserGroup/V3/GetAllUserGroups`,
    method: "post",
    data: data
  })
}
export function CreateUserGroup(data) {
  return request({
    url: `${VITE_APP_BASEURL}UserGroup/V3/CreateUserGroup`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function EditUserGroup(data) {
  return request({
    url: `${VITE_APP_BASEURL}UserGroup/V3/EditUserGroup`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function EditGroup(data) {
  return request({
    url: `${VITE_APP_BASEURL}UserGroup/V3/EditGroup`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function DeleteGroups(data) {
  return request({
    url: `${VITE_APP_BASEURL}UserGroup/V3/DeleteGroups`,
    method: "post",
    data: data
  })
}
export function GetUserDetails(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/GetUserDetails?userId=${data}`,
    method: "post",
    fullData: true
  })
}
export function UpdateUserEmail(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/UpdateUserEmail`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function UpdateUserPwd(data) {
  return request({
    url: `${VITE_APP_USERURL}User/resetOwnPassword`,
    method: "post",
    fullData: true,
    data: data
  })
}

export function GetDspAdvertiserManaged(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/GetDspAdvertiserManaged?profileId=${data}`,
    method: "post",
    fullData: true
  })
}
export function SetManagedForDspAdvertiser(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/SetManagedForDspAdvertiser`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function getUserList(data) {
  return request({
    url: `${VITE_APP_META}apply/getUserList`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function GetManagedLog(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/GetManagedLog`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function AmazonListWithChecked(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/AmazonListWithChecked?profileId=${data}`,
    method: "post",
    fullData: true
  })
}
export function SetNickName(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/SetNickName`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function DeleteAmazon(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/DeleteAmazon?advertiserId=${data}`,
    method: "post",
    fullData: true
  })
}
export function GetVendorGroup(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/GetVendorGroup`,
    method: "post",
    fullData: true
  })
}
export function GetDspAdvertiserManagedMoreLog(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/GetDspAdvertiserManagedMoreLog?advertiserId=${data}`,
    method: "post",
    fullData: true
  })
}
export function UpdateVendorAccountName(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/UpdateVendorAccountName?id=${data.id}&accountName=${data.accountName}`,
    method: "post",
    fullData: true
  })
}
export function SetUserProfile(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/SetUserProfile`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function GetBrands(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetBrands/${data}`,
    method: "GET"
  })
}
export function GetStores(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetStores/${data}`,
    method: "GET"
  })
}
export function GetAssets(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetAssets?profileId=${data.profileId}&brandEntityId=${data.brandEntityId}`,
    method: "GET",
    data: data
  })
}
export function GetSearchAssets(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetSearchAssets`,
    method: "post",
    data: data
  })
}
export function GetPageAsins(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetPageAsins`,
    method: "post",
    data: data
  })
}
export function UploadCreativeAsset(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/UploadCreativeAsset`,
    method: "post",
    data: data
  })
}
export function GetAsinByUrl(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetAsinByUrl`,
    method: "post",
    data: data
  })
}
export function GetProductAdFromProfile(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetProductAdFromProfile`,
    method: "post",
    data: data
  })
}
export function GetSponsoredBrandsCampaignAsins(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetSponsoredBrandsCampaignAsins`,
    method: "post",
    data: data
  })
}
export function GetAsinKeywordList(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetAsinKeywordList`,
    method: "post",
    data: data
  })
}
export function GetRecommendedCategories(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetRecommendedCategories`,
    method: "post",
    data: data
  })
}
export function UploadSponsoredBrandsVideo(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/UploadSponsoredBrandsVideo?profileId=${data.get("profileId")}`,
    method: "post",
    data: data
  })
}
export function UploadCreativeAssetVideo(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/UploadCreativeAsset?profileId=${data.get("profileId")}`,
    method: "post",
    data: data
  })
}
export function UploadFile(data) {
  return request({
    url: `${VITE_APP_BASEURL}File/V3/UploadFile?module=createCampaignSbImg`,
    method: "post",
    data: data
  })
}
export function SuggestedProduct(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/SuggestedProduct`,
    method: "post",
    data: data
  })
}
export function GetSBBidRecommend(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetSBBidRecommend`,
    method: "post",
    data: data
  })
}
export function CreateSponsoredBrandsCampaign(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/CreateSponsoredBrandsCampaign`,
    method: "post",
    data: data
  })
}
export function CreateVideoSponsoredBrandsCampaign(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/CreateVideoSponsoredBrandsCampaign`,
    method: "post",
    data: data
  })
}
export function CreateAsset(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/CreateAsset`,
    method: "post",
    data: data
  })
}
export function GetProfilesAndType() {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/GetAllManagerProfiles`,
    method: "post"
  })
}
export function PasswordForm(data) {
  return request({
    url: `${VITE_APP_USERURL}User/resetPassword`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function GetUserCampaignTagPermissions(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/GetUserCampaignTagPermissions?userId=${data}`,
    method: "post",
    fullData: true
  })
}
export function EditDataNew(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/EditDataNew?userId=${data}`,
    method: "post",
    fullData: true
  })
}
export function AddDataNew(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/AddDataNew`,
    method: "post",
    fullData: true
  })
}
export function SetUserForm(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/SetUserForm`,
    method: "post",
    fullData: true,
    data: data
  })
}
export function saveUser(url, data, type) {
  return request({
    url: `${VITE_APP_USERURL}${url}`,
    method: type,
    fullData: true,
    data: data
  })
}
export function DelUser(data) {
  return request({
    url: `${VITE_APP_USERURL}User/v2/${data}`,
    method: "DELETE"
  })
}

export function GetVendorBindModel(data) {
  return request({
    url: `${VITE_APP_BASEURL}Setting/V3/GetVendorBindModel`,
    method: "post",
    data: data
  })
}
export function BindVendorProfiles(data) {
  return request({
    url: `${VITE_APP_BASEURL}Setting/V3/BindVendorProfiles`,
    method: "POST",
    data: data
  })
}
export function SaveVendorProfiles(data) {
  return request({
    url: `${VITE_APP_BASEURL}Setting/V3/SaveVendorProfiles`,
    method: "POST",
    data: data
  })
}
export function GetExchangeRate() {
  return request({
    url: `${VITE_APP_BASEURL}ExchangeRate/V3/GetExchangeRate`,
    method: "POST"
  })
}
export function setSettings(data) {
  return request({
    url: `${VITE_APP_ADMIN_MIDDLE}platform/setUserPreferences`,
    method: "post",
    data: data
  })
}
export function getSettings(userId) {
  return request({
    url: `${VITE_APP_ADMIN_MIDDLE}platform/getUserPreferences/${userId}`,
    method: "GET"
  })
}

export function updateCurrency(data) {
  return request({
    url: `${VITE_APP_META}apply/updateCurrency`,
    method: "post",
    data: data
  })
}
export function CreateKeywordsandTargetingOnlyTargetClause(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/CreateKeywordsandTargetingOnlyTargetClause`,
    method: "post",
    data: data
  })
}
export function GoCheckRedirct(data, url) {
  return request({
    url: `${VITE_APP_BASEURL}Oauth2/v3/${url}`,
    method: "post",
    data: data,
    fullData: true
  })
}
export function CreatePATForAdGroup(data) {
  return request({
    url: `${VITE_APP_BASEURL}Campaign/CreatePATForAdGroup`,
    method: "post",
    data: data
  })
}
export function GetProductAdFromCampaign(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetProductAdFromCampaign`,
    method: "post",
    data: data
  })
}
export function getAllProfiles(data) {
  return request({
    url: `${VITE_APP_META}apply/getAllProfiles`,
    method: "post",
    data: data
  })
}
export function operateProfileLog(data) {
  return request({
    url: `${VITE_APP_MENU_API}user/operateProfileLog`,
    method: "post",
    data: data
  })
}
export function GetStoresByBrandEntityIdAsync(data) {
  return request({
    url: `${VITE_APP_BASEURL}CampaignCreate/V3/GetStoresByBrandEntityIdAsync?profileId=${data.profileId}&brandEntityId=${data.brandEntityId}`,
    method: "GET",
    data: data
  })
}
export function GetSBTargetsCategories(data) {
  return request({
    url: `${VITE_APP_BASEURL}SponsoredBrand/v3/GetSBTargetsCategories`,
    method: "post",
    data: data
  })
}
export function CreateCampaingPreModerationByAssertId(data) {
  return request({
    url: `${VITE_APP_BASEURL}SponsoredBrand/v3/CreateCampaingPreModerationByAssertId`,
    method: "post",
    data: data
  })
}
export function GetCreativeHeadlineRecommendation(data) {
  return request({
    url: `${VITE_APP_BASEURL}SponsoredBrand/v3/GetCreativeHeadlineRecommendation`,
    method: "post",
    data: data
  })
}
export function CreateCampaign(data) {
  return request({
    url: `${VITE_APP_BASEURL}SponsoredBrand/v3/CreateCampaign`,
    method: "post",
    data: data
  })
}
export function CheckUserIsManaged() {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/CheckUserIsManaged`,
    method: "get"
  })
}
export function profileBindUsers(data) {
  return request({
    url: `${VITE_APP_META}apply/profileBindUsers`,
    method: "post",
    data: data
  })
}
export function GetProfileAmazonPageList(data) {
  return request({
    url: `${VITE_APP_BASEURL}User/V3/GetProfileAmazonPageList`,
    method: "post",
    data: data
  })
}
export function downloadAccount(params) {
  return request({
    url: `${VITE_APP_DOWNLOAD}Account/DownloadAccountData`,
    method: "post",
    responseType: "blob",
    data: params
  })
}
export function TestRedirectProfile(data) {
  return request({
    url: `${VITE_APP_BASEURL}/Oauth2/v3/TestRedirectProfile`,
    method: "post",
    data: data,
    fullData: true
  })
}
export function GetRedirectProfile(data) {
  return request({
    url: `${VITE_APP_BASEURL}/Oauth2/v3/GetRedirectProfile`,
    method: "post",
    data: data,
    fullData: true
  })
}
export function ConfirmBindProfile(data) {
  return request({
    url: `${VITE_APP_BASEURL}/Oauth2/v3/ConfirmBindProfile`,
    method: "post",
    data: data,
    fullData: true
  })
}
